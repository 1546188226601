import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { checkAuth } from "../../store/actions/user";
import { useNavigate, Link } from "react-router-dom";
import styles from "./LoginPage.module.scss";
import Text from "components/formHelpers/Text";
import { styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";

const SubmitButton = styled(LoadingButton)(({ theme }) => ({
    color: "#FFF",
    backgroundColor: "#b797f8",
    "&:hover": {
        backgroundColor: "#b797f8",
        textDecoration: "underline",
    },
}));

function LoginPage(props) {
    const { user, sendAuthData, responseForm } = props;
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({ email: "", password: "" });
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (user.error) {
            user.error = "";
        }

        if (user.isAuth) {
            navigate("/");
        }
    }, [user, responseForm, navigate]);

    const send = async () => {
        setLoading(true);
        await sendAuthData(credentials);
        setLoading(false);
    };

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <div className={styles.wrapper}>
                    <h2>С Возвращением</h2>

                    <h3>Мы уже подобрали новые скидки для вас.</h3>

                    <h4>
                        Еще нет аккаунта?{" "}
                        <Link to="/sign-up">Зарегистрироваться</Link>
                    </h4>
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.back}>
                    <Link className={styles.link} to={"/"}>
                        Назад
                    </Link>
                    <p>Вход</p>
                </div>
                <p className={user.error !== "" ? styles.error : null}>
                    {user.error}
                </p>
                <div className={styles.hello}>
                    <h2>C возвращением 👋</h2>
                    <p>
                        Используйте данные которые вы указывали при регистрации
                    </p>
                </div>
                <div className={styles.formData}>
                    <Text
                        label="Ваш E-mail"
                        cb={(email) => {
                            setCredentials({
                                ...credentials,
                                email,
                            });
                        }}
                        value={credentials.email}
                        marginBottom={2}
                        required
                    />
                    <Text
                        label="Пароль"
                        cb={(password) => {
                            setCredentials({
                                ...credentials,
                                password,
                            });
                        }}
                        type="password"
                        value={credentials.password}
                        marginBottom={2}
                        required
                    />
                    <SubmitButton
                        onClick={() => send()}
                        endIcon={<SendIcon />}
                        loading={isLoading}
                        loadingPosition="end"
                        variant="contained"
                        sx={{ width: "100%", height: 50 }}
                    >
                        Войти
                    </SubmitButton>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (store) => {
    return {
        user: store.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendAuthData: (data) => dispatch(checkAuth(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
