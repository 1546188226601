import React, { useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { checkAuthNow, setFavoritePlace } from "store/actions/user";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
    getDiscountsByPlace,
    getPlaceById,
    getPromocodeByUser,
} from "store/actions/places";
import "./PlaceFull.scss";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { Slider } from "components/Slider/Slider";
import { DAYS } from "constants";

const saleName = (sale) => {
    if (sale > 40) {
        return "high";
    } else if (sale >= 20) {
        return "middle";
    } else if (sale >= 1) {
        return "start";
    } else {
        return "low";
    }
};

const getDay = (day) => DAYS.find((el) => el.value === day);

function PlaceFull(props) {
    const {
        user,
        hasAuth,
        getPlace,
        getDiscounts,
        current,
        discounts,
        favorites,
        total,
        setFavorite,
        getPromocode,
    } = props;
    const params = useParams();
    const navigate = useNavigate();
    const { id } = params;
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            await getPlace(id);
        })();
    }, [id, getPlace]);

    useLayoutEffect(() => {
        (async () => {
            await getDiscounts(id, user);
        })();
    }, [user, id, getDiscounts]);

    const {
        _id,
        title,
        img,
        description,
        address,
        workHours,
        sale,
        phone,
        type,
    } = current;

    const getDiscountForUser = async (id) => {
        await getPromocode(id);
    };

    const getButtonOrCoupon = (coupon, discountId) => {
        return !coupon ? (
            <button
                className="button"
                onClick={() => {
                    user === ""
                        ? navigate("/sign-in")
                        : getDiscountForUser(discountId);
                }}
            >
                {user === "" ? "Войти чтобы получить скидку" : "Получить купон"}
            </button>
        ) : (
            <div className="ticket">Ваш код: {coupon}</div>
        );
    };

    const getAllDiscounts = () => {
        return discounts.map((el) => {
            return (
                <div
                    key={el.id}
                    className={`discount ${!el.coupon ? "unActive" : null}`}
                >
                    {el.img && el.img.length ? <Slider data={el.img} /> : null}
                    <h3>{el.title}</h3>
                    {el.description && <p>{el.description}</p>}
                    <div className={`saleBlock ${saleName(el.size)}`}>
                        {el.size} %
                    </div>
                    {el.days.length ? (
                        <div className="discount__days">
                            <p>Доступные дни:</p>
                            <div className="discount__days-items">
                                {el.days.map((day) => (
                                    <p className="discount__days-day" key={day}>
                                        {getDay(day)?.title}
                                    </p>
                                ))}
                            </div>
                        </div>
                    ) : null}

                    {el?.timeslots?.length ? (
                        <p>
                            Ближайшее время: <b>{el.timeslots}</b>
                        </p>
                    ) : null}

                    {getButtonOrCoupon(el.coupon, el.id)}
                </div>
            );
        });
    };

    const checkWishlist = () => {
        return favorites.find((el) => el === _id);
    };

    const setWish = async () => {
        if (!isLoading) {
            setLoading(true);
            await setFavorite(_id);
            await hasAuth();
            setLoading(false);
        }
    };

    return (
        current.title !== undefined && (
            <div className="placefull">
                <Link className="back" to="/" />
                <Slider data={img} />

                <div className={`saleBlock ${saleName(total)}`}>{total} %</div>
                <div className="about">
                    <h2 className="name">{title}</h2>
                    <p className="types">{type}</p>
                    <p className="desc">{description}</p>
                    <p className="markdown">{address}</p>
                    <p className="workTime">{`${
                        workHours.start
                            ? workHours.start + " - " + workHours.end
                            : workHours
                    }`}</p>

                    {sale !== 0 && discounts ? getAllDiscounts() : null}
                    <a href={`tel: + ${phone}`} className="callTo">
                        Позвонить
                    </a>
                    {user && (
                        <button
                            className={`placefull__favorite ${
                                !checkWishlist()
                                    ? "placefull__favorite-add"
                                    : "placefull__favorite-remove"
                            }`}
                            onClick={() => {
                                setWish(checkWishlist());
                            }}
                        >
                            {checkWishlist() ? (
                                <>
                                    <Favorite className="placefull__favorite-icon" />
                                    Удалить из любимых
                                </>
                            ) : (
                                <>
                                    <FavoriteBorder className="placefull__favorite-icon" />
                                    Добавить в любимые
                                </>
                            )}
                        </button>
                    )}
                </div>
            </div>
        )
    );
}

const mapStateToProps = (store) => {
    const { discounts, data: current, total } = store.places.current;
    return {
        user: store.user.id,
        favorites: store.user.favorites,
        discounts,
        current,
        total,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        hasAuth: () => dispatch(checkAuthNow()),
        getPlace: (id) => dispatch(getPlaceById(id)),
        getDiscounts: (id, user) => dispatch(getDiscountsByPlace(id, user)),
        setFavorite: (id) => dispatch(setFavoritePlace(id)),
        getPromocode: (id) => dispatch(getPromocodeByUser(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceFull);
