import {
    GET_PLACES_REQUEST,
    GET_PLACES_SUCCESS,
    SET_FILTERED_SEARCH_RESULTS,
    SET_FILTERED_PLACES,
    ADD_REPLACE_CATEGORIES,
    SET_CURRENT_PLACE,
    SET_CURRENT_DISCOUNTS,
    CLEAR_SEARCH_RESULTS,
    SET_SEARCH_KEYWORDS,
    SET_PROMOCODE,
} from "../actions/places";

const initialState = {
    places: [],
    categories: [],
    list: [],
    filtered: [],
    search: {
        results: [],
        keywords: "",
    },
    current: {
        data: {},
        discounts: [],
        total: 0,
    },
};

export const placesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PLACES_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_PLACES_SUCCESS:
            return {
                ...state,
                places: action.payload,
                list: action.payload.filter((place) => place.mapPoint.length),
                isLoading: false,
            };
        case SET_FILTERED_SEARCH_RESULTS:
            return {
                ...state,
                search: {
                    ...state.search,
                    results: [...state.search.results, ...action.payload],
                },
            };
        case SET_FILTERED_PLACES:
            return {
                ...state,
                list: state.categories.length
                    ? action.payload
                    : state.places.filter((place) => place.mapPoint.length),
            };

        case ADD_REPLACE_CATEGORIES:
            let index = state.categories.indexOf(action.payload);
            return index !== -1
                ? {
                      ...state,
                      categories: [
                          ...state.categories.slice(0, index),
                          ...state.categories.slice(index + 1),
                      ],
                  }
                : {
                      ...state,
                      categories: [...state.categories, action.payload],
                  };
        case SET_CURRENT_PLACE:
            return {
                ...state,
                current: {
                    ...state.current,
                    data: action.payload,
                },
            };

        case SET_CURRENT_DISCOUNTS:
            const { discounts, total } = action.payload;
            return {
                ...state,
                current: {
                    ...state.current,
                    discounts,
                    total,
                },
            };
        case CLEAR_SEARCH_RESULTS:
            return {
                ...state,
                search: {
                    ...state.search,
                    results: [],
                },
            };
        case SET_SEARCH_KEYWORDS:
            return {
                ...state,
                search: {
                    ...state.search,
                    keywords: action.payload,
                },
            };
        case SET_PROMOCODE:
            const i = state.current.discounts.findIndex(
                (el) => el.id === action.payload.discount
            );

            let _discounts = state.current.discounts;

            if (i !== -1) {
                _discounts[i] = {
                    ..._discounts[i],
                    coupon: action.payload.code,
                };
            }
            return {
                ...state,
                current: state.current,
            };
        default:
            return state;
    }
};
