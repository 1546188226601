import { reject } from "lodash";
import {
    GET_CUSTOMER_PLACES_REQUEST,
    SET_CUSTOMER_PLACES_SUCCESS,
    SET_CUSTOMER_CURRENT_PLACE,
    SET_MODERATOR_PLACES_SUCCESS,
    SET_MODERATOR_USERS_SUCCESS,
    DELETE_MODERATOR_PLACE,
    DELETE_MODERATOR_USER,
    ADD_USER_PERMISSION,
    SET_ACTIVE_FOR_USER,
    DELETE_USER_PERMISSION,
    SET_DISCOUNTS,
    DELETE_DISCOUNT,
} from "../actions/customer";

const initialState = {
    places: [],
    current: {},
    discounts: {
        past: [],
        current: [],
    },
    moderation: {
        places: [],
        users: [],
    },
};

export const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOMER_PLACES_REQUEST:
            return { ...state };
        case SET_CUSTOMER_PLACES_SUCCESS:
            return { ...state, places: action.payload.places };
        case SET_CUSTOMER_CURRENT_PLACE:
            return {
                ...state,
                current: state.places.find((el) => el._id === action.payload),
            };
        case SET_MODERATOR_PLACES_SUCCESS:
            return {
                ...state,
                moderation: {
                    ...state.moderation,
                    places: [...action.payload.places],
                },
            };

        case SET_MODERATOR_USERS_SUCCESS:
            return {
                ...state,
                moderation: {
                    ...state.moderation,
                    users: [...action.payload.users],
                },
            };

        case SET_ACTIVE_FOR_USER:
            let index = state.moderation.users.findIndex(
                (user) => user.id === action.payload
            );
            state.moderation.users[index] = {
                ...state.moderation.users[index],
                valid: true,
            };

            return {
                ...state,
                moderation: {
                    ...state.moderation,
                    users: [...state.moderation.users],
                },
            };

        case ADD_USER_PERMISSION:
            let foundIndex = state.moderation.users.findIndex(
                (user) => user.id === action.payload.id
            );
            state.moderation.users[foundIndex] = {
                ...state.moderation.users[foundIndex],
                permissions: [
                    ...state.moderation.users[foundIndex].permissions,
                    action.payload.role,
                ],
            };

            return {
                ...state,
                moderation: {
                    ...state.moderation,
                    users: [...state.moderation.users],
                },
            };

        case DELETE_MODERATOR_PLACE:
            return {
                ...state,
                moderation: {
                    ...state.moderation,
                    places: state.moderation.places.filter(
                        (place) => place._id !== action.payload
                    ),
                },
            };

        case DELETE_MODERATOR_USER:
            return {
                ...state,
                moderation: {
                    ...state.moderation,
                    users: reject(state.moderation.users, {
                        id: action.payload,
                    }),
                },
            };
        case DELETE_USER_PERMISSION:
            let inx = state.moderation.users.findIndex(
                (user) => user.id === action.payload.id
            );
            state.moderation.users[inx] = {
                ...state.moderation.users[inx],
                permissions: state.moderation.users[inx].permissions.filter(
                    (el) => el !== action.payload.role
                ),
            };
            return {
                ...state,
                moderation: {
                    ...state.moderation,
                    users: [...state.moderation.users],
                },
            };
        case SET_DISCOUNTS:
            return {
                ...state,
                discounts: {
                    ...state.discounts,
                    [action.payload.type]: [...action.payload.data],
                },
            };

        case DELETE_DISCOUNT:
            const { id: _id, type } = action.payload;
            return {
                ...state,
                discounts: {
                    ...state.discounts,
                    [type]: reject(state.discounts[type], { _id }),
                },
            };
        default:
            return state;
    }
};
