import BackButton from "components/BackButton/BackButton";
import CropImage from "components/CropImage/CropImage";
import CustomerHeader from "components/CustomerHeader/CustomerHeader";
import Header from "components/Header/Header";
import ImageUploader from "components/ImageUploader/ImageUploader";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "services/api-service";
import styles from "../sub-pages/subPages.module.scss";
import { CircularProgress } from "@mui/material";

function EditImages(props) {
    const params = useParams();
    const { id } = params;
    const service = new ApiService();
    const [allImages, setAllImages] = useState([]);
    const [preview, setPreview] = useState("");
    const [isCropped, setStatusCropped] = useState(false);
    const [cropped, setCropped] = useState("");
    const [isUploading, setUploading] = useState(false);

    useEffect(() => {
        (async function getAllImages() {
            const res = await service.getAllImagesForPlace(id);
            setAllImages(res.data.img);
            setPreview(res.data.preview.parent);
        })();
        // eslint-disable-next-line
    }, []);

    const cropImage = (index) => {
        setCropped(`/${allImages[index]}`);
        setStatusCropped(true);
    };

    const renderAllImages = () => {
        return allImages.map((el, index) => {
            return (
                <div className={styles.imgBlock} key={el}>
                    <img src={`/${el}`} alt="" />
                    <button
                        className={
                            el === preview ? styles.yellow : styles.green
                        }
                        onClick={() => {
                            cropImage(index);
                        }}
                    >
                        {el === preview
                            ? "Изменить область"
                            : "Сделать основной"}
                    </button>
                    <button
                        className={styles.red}
                        onClick={() => {
                            deleteImage(index);
                        }}
                    >
                        Удалить
                    </button>
                </div>
            );
        });
    };

    const deleteImage = async (index) => {
        const res = await service.deletePlaceImage({ id, index });
        setAllImages(res.data);
    };

    const uploadImage = async (data) => {
        if (!data.length) return;
        const formData = new FormData();
        for (var i = 0; i < data.length; i++) {
            formData.append("file_" + i, data[i]);
        }
        formData.append("id", id);
        try {
            setUploading(true);
            const res = await service.uploadPlaceImage(formData);
            if (res.status === 200) {
                setUploading(false);
                setAllImages(res.data);
            }
        } catch {
            setUploading(false);
        }
    };

    const onSavePreview = async (file) => {
        const _file = new File([file], "prev.jpg", {
            type: file.type,
        });
        const formData = new FormData();
        formData.append("file_0", _file);
        formData.append("id", id);
        formData.append("parent", cropped.substring(1));
        const res = await service.uploadPreview(formData);
        if (res.status === 200) {
            setAllImages(res.data);
        }

        setStatusCropped(false);
        setCropped("");
    };

    return (
        <>
            <Header />
            <CustomerHeader />
            <div className={styles.container}>
                <h3 className={styles.titleCustomer}>Изменить изображения</h3>
                <ImageUploader onUpload={uploadImage} immediatly />

                {isCropped && (
                    <CropImage src={cropped} onSave={onSavePreview} />
                )}
                <div className={styles.gallery}>
                    {allImages.length > 0 ? renderAllImages() : null}

                    {isUploading && (
                        <div className={styles.imgBlockLoading}>
                            <CircularProgress />
                            Идет загрузка изображения
                        </div>
                    )}
                </div>

                <BackButton />
            </div>
        </>
    );
}

export default EditImages;
