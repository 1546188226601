import React, { useState } from "react";

import Search from "components/Search";
import CitySelector from "components/CitySelector/CitySelector";

import styles from "./Sidebar.module.scss";

function SidebarHeader(props) {
    const { children, hideCity } = props;
    //eslint-disable-next-line
    const [_, setCityVisibility] = useState(true);

    return (
        <>
            <div className={styles.sidebar__header}>
                {!hideCity && <CitySelector />}

                <Search
                    isActive={(status) => {
                        setCityVisibility(status);
                    }}
                />
            </div>
            {children}
        </>
    );
}

export default SidebarHeader;
