import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import CategoryList from "components/CategoryList/CategoryList";
import PlaceFull from "components/PlaceFull/PlaceFull";
import PlacesList from "components/PlacesList/PlacesList";

import styles from "./Sidebar.module.scss";

import UserPage from "Pages/UserPage";
import { getPlacesForCurrentCity } from "../../store/actions/places";
import { checkAuthNow } from "store/actions/user";

import { Route, Routes } from "react-router-dom";

import SearchPage from "Pages/SearchPage/SearchPage";
import SidebarHeader from "./SidebarHeader";
import Promocodes from "Pages/Promocodes/Promocodes";
import Favorite from "Pages/Favorite/Favorite";
import ConfidentalPolicy from "Pages/ConfidentalPolicy/ConfidentalPolicy";
import { setActiveCategories } from "store/actions/places";

function Sidebar(props) {
    const { city } = props.city;
    const { setCategories, activeCategories } = props;

    const { hasAuth, getPlacesForCity } = props;

    //eslint-disable-next-line
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        hasAuth();
    }, [hasAuth]);

    useEffect(() => {
        (async function () {
            setLoading(true);
            if (city) {
                await getPlacesForCity(city);
                setLoading(false);
            }
        })();
    }, [getPlacesForCity, city]);

    return (
        <div className={styles.sidebar}>
            <Routes>
                <Route path="/place/:id" element={<PlaceFull />} />
                <Route
                    path="/search"
                    element={
                        <SidebarHeader hideCity>
                            <SearchPage />
                        </SidebarHeader>
                    }
                />
                <Route path="/me" element={<UserPage />} />
                <Route path="/me/favorite" element={<Favorite />} />
                <Route path="/me/promocodes" element={<Promocodes />} />
                <Route path="/privacy" element={<ConfidentalPolicy />} />
                <Route
                    path="/"
                    element={
                        <SidebarHeader>
                            {activeCategories && (
                                <CategoryList
                                    list={activeCategories}
                                    update={setCategories}
                                />
                            )}
                            <PlacesList />
                        </SidebarHeader>
                    }
                />
            </Routes>
        </div>
    );
}

const mapStateToProps = (store) => {
    return {
        city: store.city,
        activeCategories: store.places.categories,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        hasAuth: () => dispatch(checkAuthNow()),
        setCategories: (category) => dispatch(setActiveCategories(category)),
        getPlacesForCity: (location) =>
            dispatch(getPlacesForCurrentCity(location)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
