import { isEqual } from "lodash";

export const compileTimeslots = (slots) => {
    const _arrSlots = [];

    Object.entries(slots).forEach((slot) => {
        slot[1].forEach((time) => {
            const _index = _arrSlots.findIndex((timeslot) =>
                isEqual(time, timeslot.time)
            );

            if (_index !== -1) {
                _arrSlots[_index] = {
                    ..._arrSlots[_index],
                    days: [..._arrSlots[_index].days, slot[0]],
                };
            } else {
                _arrSlots.push({
                    days: [slot[0]],
                    time,
                });
            }
        });
    });

    return _arrSlots;
};

export const parseTimeslots = (slots) => {
    const _slotsObj = {};

    slots.forEach((slot) => {
        slot.days.forEach((day) => {
            _slotsObj[day] = _slotsObj[day]
                ? [..._slotsObj[day], slot.time]
                : [slot.time];
        });
    });

    return _slotsObj;
};
