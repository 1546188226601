import Place from "components/Place/Place";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFavoritePlaces } from "store/actions/user";
import "./Favorite.scss";

function Favorite(props) {
    const { getFavorites, favorites, id: user } = props;

    const navigate = useNavigate();

    useEffect(() => {
        if (!user) navigate("/");

        const fetch = async () => {
            await getFavorites();
        };

        fetch();
        //eslint-disable-next-line
    }, []);

    return (
        <div className="favorite">
            <h2 className="favorite__title">Ваши любимые заведения</h2>

            <div className="favorite__list">
                {favorites.map((place) => (
                    <Place
                        id={place._id}
                        title={place.title}
                        address={place.address}
                        img={place.img[0]}
                        sale={place.sale}
                        view={place.views}
                        gotCoupons={place.gotCoupons}
                    />
                ))}
            </div>
        </div>
    );
}

const mapStateToProps = (store) => {
    return {
        favorites: store.user.favorites,
        id: store.user.id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFavorites: () => dispatch(getFavoritePlaces()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Favorite);
