import React, { useState } from "react";
import SingleDay from "./SingleDay";
import { DAYS } from "constants";

import "./styles.scss";
import { xor } from "lodash";
import { FormControlLabel, Switch } from "@mui/material";

function DaysPicker({
    days,
    slots,
    updateDays,
    updateSlots,
    defaultDuration = false,
}) {
    const [haveDuration, setVisibleDuration] = useState(defaultDuration);

    const renderDays = (data, checked) =>
        data.map((day) => (
            <SingleDay
                key={day.value}
                title={day.title}
                value={day.value}
                checked={checked}
                duration={checked && haveDuration}
                handleTimeslots={(time) => {
                    updateTimeslots(day.value, time);
                }}
                timeslots={slots[day.value] || []}
                onSelect={toggleDays}
            />
        ));

    const getActiveDays = () =>
        renderDays(
            DAYS.filter((day) => days.includes(day.value)),
            true
        );

    const getUnActiveDays = () =>
        renderDays(DAYS.filter((day) => !days.includes(day.value)));

    const toggleDays = (day) => {
        updateDays(xor(days, [day]));
    };

    const updateTimeslots = (day, time) => {
        const dayExist = slots[day] || [];

        updateSlots({ ...slots, [day]: [...dayExist, time] });
    };

    return (
        <div className="days-picker">
            <h3>Кликните по дню чтобы поменять его статус</h3>
            {days.length ? (
                <FormControlLabel
                    control={
                        <Switch
                            onChange={() => {
                                setVisibleDuration((prev) => !prev);
                            }}
                            color="primary"
                            checked={haveDuration}
                        />
                    }
                    label="Настройка времени по дням"
                    labelPlacement="start"
                    sx={{
                        justifyContent: "start",
                        padding: 0,
                        margin: 0,
                    }}
                />
            ) : null}

            <div
                className={`days-picker__active ${
                    haveDuration ? "days-picker__active-column" : null
                }`}
            >
                <h2>Активные дни акции</h2>
                {getActiveDays()}
            </div>
            <div className="days-picker__unactive">
                <h2>Неактивные дни акции</h2>
                {getUnActiveDays()}
            </div>
        </div>
    );
}

export default DaysPicker;
