import React, { useState } from "react";
import styles from "./CustomerPlace/sub-pages/subPages.module.scss";
import {
    getUsersForModeration,
    addPermisionsById,
    deletePermisionsById,
    activateUserById,
    removeUserById,
} from "store/actions/customer";
import CustomerHeader from "components/CustomerHeader/CustomerHeader";
import BackButton from "components/BackButton/BackButton";
import { connect } from "react-redux";
import {
    styled,
    TextField,
    Card,
    CardHeader,
    Typography,
    CardContent,
    Avatar,
    CardActions,
    IconButton,
    Tooltip,
    Modal,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
} from "@mui/material";

import {
    DoDisturb,
    LocalActivity,
    VerifiedUser,
    AdminPanelSettings,
} from "@mui/icons-material";
import RequirePermissions from "hoc/RequirePermissions";
import { Box } from "@mui/system";
import { xor } from "lodash";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Spinner from "components/Spinner/Spinner";
import Header from "components/Header/Header";

const CustomInput = styled(TextField)({
    "& label.Mui-focused": {
        color: "green",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#00d07a",
    },
    "& .MuiOutlinedInput-root": {
        paddingLeft: 10,
        marginBottom: 10,

        "& fieldset": {
            borderColor: "#00d07a",
        },
        "&:hover fieldset": {
            borderColor: "#00d07a",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#00d07a",
        },
    },
});

const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#FFF",
    boxShadow: 24,
    p: 4,
};

function ModerationUser(props) {
    const {
        users,
        cities,
        getUsersForModeration,
        activateUser,
        addPermisions,
        removePermisions,
        removeUser,
    } = props;

    const [query, setQuery] = useState("");
    const [selectedCities, setSelectedCities] = useState([]);
    const [expiredDate, setExpiredDate] = useState(null);
    const [editableUser, setEditableUser] = useState(null);
    const [visibleModal, setVisibleModal] = useState(false);

    const getRoles = (permissions) => {
        let roles = permissions.map((el) => {
            switch (el) {
                case "moderator":
                    return "Модератор";
                case "admin":
                    return "Администратор";
                case "user":
                    return "Пользователь";
                case "manager":
                    return "Мeнеджер";
                case "owner":
                    return "Владелец";
                default:
                    break;
            }
            return "";
        });
        return roles.join(", ");
    };

    const getIsoDate = (el) => {
        let date = Number(el) * 1000;
        return new Date(date).toUTCString().slice(0, -4);
    };

    const handlePermission = (id, role) => {
        setEditableUser({ id, role });
        setVisibleModal(true);
    };

    const onAddPermission = async () => {
        addPermisions({
            ...editableUser,
            cities: selectedCities,
            expired: expiredDate,
        });

        setVisibleModal(false);
        setEditableUser(null);
        setExpiredDate(null);
        setSelectedCities([]);
    };

    const renderAllUsers = () => {
        return users.map((user) => {
            const isActive = user.valid;
            const isOwner = user.permissions.includes("owner");
            const isManager = user.permissions.includes("manager");
            const isAdmin = user.permissions.includes("admin");

            return (
                <Card sx={{ width: 345 }} key={user.id}>
                    <CardHeader
                        avatar={
                            <Avatar
                                sx={{ bgcolor: "#00d07a" }}
                                aria-label="recipe"
                                src={`/${user.avatar}`}
                            >
                                {user.name[0]}
                            </Avatar>
                        }
                        title={user.name}
                        subheader={
                            user.lastVisited !== "0"
                                ? `Заходил последний раз: ${getIsoDate(
                                      user.lastVisited
                                  )}`
                                : "Не заходил в систему"
                        }
                    />
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            <b>Подтвердил E-mail:</b>{" "}
                            {user.valid ? "Да" : "Нет"}
                        </Typography>

                        <Typography variant="body2" color="text.secondary">
                            <b>E-mail:</b> {user.email}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <b>Купонов:</b> {user.coupons}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <b>Подписан на заведения:</b> {user.wishList}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <b>Роли:</b> {getRoles(user.permissions)}
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Tooltip
                            title={
                                isOwner
                                    ? "Владелец заведения"
                                    : "Дать права владельца заведения"
                            }
                        >
                            <IconButton
                                onClick={() => {
                                    !isOwner
                                        ? handlePermission(user.id, "owner")
                                        : removePermisions({
                                              id: user.id,
                                              role: "owner",
                                          });
                                }}
                                aria-label="Дать права владельца заведения"
                            >
                                <LocalActivity
                                    color={isOwner ? "success" : ""}
                                />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={
                                isActive
                                    ? "Пользователь подтвердил емаил"
                                    : "Активировать пользователя"
                            }
                        >
                            <IconButton
                                onClick={() => {
                                    if (!isActive) {
                                        activateUser(user.id);
                                    }
                                }}
                                aria-label="Активировать пользователя"
                            >
                                <VerifiedUser
                                    color={isActive ? "success" : ""}
                                />
                            </IconButton>
                        </Tooltip>
                        <RequirePermissions all="admin">
                            <Tooltip
                                title={
                                    isManager
                                        ? "Менеджер"
                                        : "Добавить права менеджера"
                                }
                            >
                                <IconButton
                                    onClick={() => {
                                        !isManager
                                            ? handlePermission(
                                                  user.id,
                                                  "manager"
                                              )
                                            : removePermisions({
                                                  id: user.id,
                                                  role: "manager",
                                              });
                                    }}
                                    aria-label="Добавить права менеджера"
                                >
                                    <AdminPanelSettings
                                        color={isManager ? "success" : ""}
                                    />
                                </IconButton>
                            </Tooltip>
                        </RequirePermissions>

                        {!isAdmin && (
                            <RequirePermissions all="admin">
                                <Tooltip title="Удалить пользователя">
                                    <IconButton
                                        onClick={() => {
                                            removeUser(user.id);
                                        }}
                                        aria-label="Удалить пользователя"
                                    >
                                        <DoDisturb color="error" />
                                    </IconButton>
                                </Tooltip>
                            </RequirePermissions>
                        )}
                    </CardActions>
                </Card>
            );
        });
    };

    const find = async () => {
        if (query.length > 1) {
            await getUsersForModeration(query);
        }
    };

    const isReady = () => Boolean(cities);

    return (
        <>
            {isReady() ? (
                <>
                    <Header />
                    <CustomerHeader />
                    <div className={styles.container}>
                        <h3 className={styles.titleCustomer}>
                            Управление пользователями
                        </h3>
                        <p>Введите email или имя для поиска пользователя</p>
                        <div className={styles.searchFlex}>
                            <CustomInput
                                label="Данные пользователя"
                                id="custom-css-outlined-input"
                                value={query}
                                InputLabelProps={{
                                    style: {
                                        fontSize: 17,
                                    },
                                }}
                                onChange={(e) => {
                                    setQuery(e.target.value);
                                }}
                                sx={{ width: 340 }}
                            />
                            <button
                                onClick={find}
                                className={styles.btn}
                                style={{ marginTop: 0 }}
                            >
                                Поиск
                            </button>
                        </div>
                        {users.length > 0 && (
                            <div className={styles.flex}>
                                {renderAllUsers()}
                            </div>
                        )}

                        <Modal
                            open={visibleModal}
                            onClose={() => {
                                setVisibleModal(false);
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={styleModal}>
                                <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                >
                                    Добавление прав пользователю
                                </Typography>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DatePicker
                                        value={expiredDate || null}
                                        onChange={(date) =>
                                            setExpiredDate(date)
                                        }
                                        color="green"
                                        inputFormat="dd/MM/yyyy HH:mm"
                                        mask="__/__/____ __:__"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Дата окончания подписки"
                                                defaultValue="2017-05-24T10:30"
                                                sx={{
                                                    width: 250,
                                                    marginTop: 3,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>

                                <Typography
                                    id="modal-modal-description"
                                    sx={{ mt: 2 }}
                                >
                                    Выберите город(а) доступные пользователю
                                </Typography>

                                <FormGroup>
                                    {cities.map((city) => (
                                        <FormControlLabel
                                            key={city._id}
                                            control={
                                                <Checkbox
                                                    onClick={() =>
                                                        setSelectedCities(
                                                            xor(
                                                                selectedCities,
                                                                [city._id]
                                                            )
                                                        )
                                                    }
                                                />
                                            }
                                            checked={selectedCities.includes(
                                                city._id
                                            )}
                                            label={city.name}
                                        />
                                    ))}
                                </FormGroup>

                                <Button
                                    sx={{ marginTop: 2 }}
                                    variant="outlined"
                                    color="success"
                                    onClick={onAddPermission}
                                >
                                    {`Добавить права ${getRoles([
                                        editableUser?.role || null,
                                    ])}а`}
                                </Button>
                            </Box>
                        </Modal>
                        <BackButton />
                    </div>
                </>
            ) : (
                <Spinner />
            )}
        </>
    );
}

const mapStateToProps = (store) => {
    return {
        users: store.customer.moderation.users,
        cities: store.user.subscription?.cities,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUsersForModeration: (query) =>
            dispatch(getUsersForModeration(query)),
        activateUser: (id) => dispatch(activateUserById(id)),
        addPermisions: (payload) => dispatch(addPermisionsById(payload)),
        removePermisions: (payload) => dispatch(deletePermisionsById(payload)),
        removeUser: (id) => dispatch(removeUserById(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModerationUser);
