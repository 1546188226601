import React, { useState } from "react";

import Sidebar from "components/Sidebar";
import MainMap from "components/MainMap";

import styles from "./MainPage.module.scss";
import Header from "components/Header/Header";
import CookiesModal from "components/CookiesModal/CookiesModal";

function MainPage(props) {
    const [shoutPlace, setShoutPlace] = useState("");
    const [showMap, setShowMap] = useState(true);

    const openPlace = (id) => {
        setShoutPlace(id);
    };

    const isAccepted = () => localStorage.getItem("privacy-accept");

    return (
        <>
            <Header />
            <div
                className={
                    styles.container + " " + (!showMap ? styles.hideMap : null)
                }
            >
                <Sidebar updatePlace={shoutPlace} showMap={setShowMap} />
                <MainMap openPlace={openPlace} />
                {!isAccepted() ? <CookiesModal /> : null}
            </div>
        </>
    );
}

export default MainPage;
