import React, { useState } from "react";
import { connect } from "react-redux";
import styles from "./UserPage.module.scss";
import ApiService from "../../services/api-service";
import WishesList from "components/WishesList/WishesList";

function LoginPage(props) {
  const { user, close, toPlace } = props;
  const [showCoupons, setShowCoupons] = useState(false);
  const [showWishes, setShowWishes] = useState(false);
  const [allCoupons, setAllCoupons] = useState([]);
  const [allWishes, setAllWishes] = useState([]);
  const service = new ApiService();

  const getCoupons = async () => {
    const res = await service.getCoupons({ id: user.id });
    setAllCoupons(res.coupons);
    setShowCoupons(true);
  };

  const getWishes = async () => {
    const wishes = await service.getWishes({ id: user.id });
    setAllWishes(wishes);
    setShowWishes(true);
  };

  const saleName = (sale) => {
    if (sale > 40) {
      return "high";
    } else if (sale >= 20) {
      return "middle";
    } else if (sale >= 1) {
      return "start";
    } else {
      return "low";
    }
  };

  const coupons = () => {
    return allCoupons.map((el) => {
      return (
        <div key={el.name}>
          <h4>{el.name}</h4>
          <p className={styles.size + ` ` + styles[saleName(el.size)]}>{el.size}%</p>
          <p>
            Код купона: <span>{el.coupon}</span>
          </p>
        </div>
      );
    });
  };

  const buttonToCoupons = () => {
    return (
      <div className={styles.buttons}>
        <button className={styles.btn} onClick={getWishes}>
          Любимые места
        </button>
        <button className={styles.btn} onClick={getCoupons}>
          Мои купоны
        </button>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {showCoupons || showWishes ? (
        <button
          className={styles.btn}
          onClick={() => {
            setShowCoupons(false);
            setShowWishes(false);
          }}
        >
          В профиль
        </button>
      ) : (
        <button className={styles.btn} onClick={close}>
          К заведениям
        </button>
      )}
      <h2>{user.name}</h2>
      <img className={styles.avatar} src={user.avatar} alt={user.name} />
      <p className={user.error !== "" ? styles.error : null}>{user.error}</p>
      {showCoupons &&
        (allCoupons.length ? (
          <div className={styles.couponsContainer}>{coupons()}</div>
        ) : (
          <div className={styles.couponsContainer}>
            <h3>У вас еще нету купонов</h3>
          </div>
        ))}
      {showWishes && (
        <div className={styles.wishesContainer}>
          <h3>Любимые заведения</h3>
          <WishesList
            places={allWishes}
            toPlace={(id) => {
              toPlace(id);
            }}
          />
        </div>
      )}
      {!showCoupons && !showWishes && buttonToCoupons()}
    </div>
  );
}

const mapStateToProps = (store) => {
  return {
    user: store.user,
  };
};

export default connect(mapStateToProps)(LoginPage);
