import React, { useState } from "react";
import ApiService from "../../services/api-service";

import Text from "../../components/formHelpers/Text";

import { LoadingButton } from "@mui/lab";
import { styled, Alert } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import { Link } from "react-router-dom";

import { Slider } from "components/Slider/Slider";

import styles from "./SignUpPage.module.scss";
import {
    Map,
    NotificationsActive,
    Store,
    SavedSearch,
} from "@mui/icons-material";

const SubmitButton = styled(LoadingButton)(({ theme }) => ({
    color: "#FFF",
    backgroundColor: "#b797f8",
    "&:hover": {
        backgroundColor: "#b797f8",
        textDecoration: "underline",
    },
}));

function SignUpPage(props) {
    const service = new ApiService();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        repeat: "",
    });
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const fieldsEmpty = () => {
        for (let field in formData) {
            if (formData[field] === "") {
                return true;
            }
        }
        return false;
    };

    const correctEmail = () => {
        const emailValid = formData.email.match(
            /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        );
        return emailValid ? false : true;
    };

    const hasValid = () => {
        if (fieldsEmpty()) {
            setError("Заполните все данные формы");
            return false;
        }

        if (correctEmail()) {
            setError("Некоректный email");
            return false;
        }

        if (formData.password !== formData.repeat) {
            setError("Пароли не совпадают");
            return false;
        }
        setError("");
        return true;
    };

    const handleClick = async (e) => {
        e.preventDefault();
        if (hasValid()) {
            setLoading(true);
            const res = await service.sendSignUp(formData);
            if (res.status === 200) {
                setSuccess(true);
            } else {
                setError(res.data);
            }
            setLoading(false);
        }
    };

    const data = [
        {
            img: <Map sx={{ scale: "5", color: "#FFF" }} />,
            text: "Легко находить скидки рядом с Вами",
        },
        {
            img: <NotificationsActive sx={{ scale: "5", color: "#FFF" }} />,
            text: "Получайте уведомления о акциях любимых заведений",
        },
        {
            img: <Store sx={{ scale: "5", color: "#FFF" }} />,
            text: "Большой выбор категорий на любой вкус",
        },
        {
            img: <Store sx={{ scale: "5", color: "#FFF" }} />,
            text: "Большой выбор категорий на любой вкус",
        },
        {
            img: <SavedSearch sx={{ scale: "5", color: "#FFF" }} />,
            text: "Удобный поиск по заведениям и акциям",
        },
    ];

    return (
        <div className={styles.signUp}>
            <div className={styles.left}>
                <div className={styles.wrapper}>
                    <h2>Начнем экономить?</h2>
                    <Slider
                        type="content"
                        data={data}
                        classes="margin-increment-50"
                    />
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.back}>
                    <Link className={styles.link} to={"/"}>
                        Назад
                    </Link>
                    <p>Регистрация</p>
                </div>
                {success ? (
                    <div className={styles.success}>
                        Вы успешно зарегистрировались, проверьте email чтобы
                        подтвердить регистрацию.
                    </div>
                ) : (
                    <div className={styles.formData}>
                        {error && (
                            <Alert
                                severity="error"
                                sx={{
                                    marginBottom: 5,
                                    position: "absolute",
                                    top: 0,
                                    width: "90%",
                                }}
                            >
                                {error}
                            </Alert>
                        )}
                        <Text
                            label="Имя"
                            cb={(name) => {
                                setFormData({
                                    ...formData,
                                    name,
                                });
                            }}
                            value={formData.name}
                            required
                        />
                        <Text
                            label="E-mail"
                            cb={(email) => {
                                setFormData({
                                    ...formData,
                                    email,
                                });
                            }}
                            value={formData.email}
                            required
                        />

                        <Text
                            label="Пароль"
                            cb={(password) => {
                                setFormData({
                                    ...formData,
                                    password,
                                });
                            }}
                            value={formData.password}
                            type="password"
                            required
                        />

                        <Text
                            label="Повторите пароль"
                            cb={(repeat) => {
                                setFormData({
                                    ...formData,
                                    repeat,
                                });
                            }}
                            value={formData.repeat}
                            type="password"
                            required
                        />

                        <SubmitButton
                            onClick={handleClick}
                            endIcon={<SendIcon />}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            sx={{ width: "100%", height: 50 }}
                        >
                            Зарегистрироваться
                        </SubmitButton>

                        <p className={styles.msg}>
                            Уже есть аккаунт?{" "}
                            <Link className={styles.link} to={"/sign-in"}>
                                Перейти на страницу входа
                            </Link>
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SignUpPage;
