import React from "react";
import ReactDOM from "react-dom";
import store from "./store/index";
import { Provider } from "react-redux";
import App from "./components/App/App";
import { YMaps } from "react-yandex-maps";
import "./index.css";

ReactDOM.render(
    <Provider store={store}>
        <YMaps query={{ apikey: "fea62b2e-a62d-4c2b-8739-041665ff8d4e" }}>
            <App />
        </YMaps>
    </Provider>,
    document.getElementById("root")
);
