import {
    SEND_USER_DATA,
    SET_USER_DATA,
    CLEAR_USER_DATA,
    SET_USER_ERROR,
    SEND_USER_LOGOUT,
    SET_FAVORITES_DATA,
    DELETE_NOTIFICATION,
    SET_PROMOCODES,
    SET_PERMISSIONS,
} from "../actions/user";

const initialState = {
    id: "",
    name: "",
    isAuth: false,
    avatar: "",
    error: "",
    notifications: [],
    permissions: [],
    subscription: {},
    favorites: [],
    promocodes: [],
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_USER_DATA:
            return {
                ...state,
                isLoading: true,
            };
        case SET_USER_DATA:
            return {
                ...state,
                id: action.payload.id,
                isAuth: action.payload.isAuth,
                name: action.payload.name,
                isLoading: false,
                avatar: action.payload.avatar,
                notifications: [...action.payload.notifications],
                permissions: [...action.payload.permissions],
                subscription: { ...action.payload.subscription },
                error: "",
            };
        case SET_USER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case SEND_USER_LOGOUT:
            return {
                ...state,
                id: "",
                name: "",
                avatar: "",
                error: "",
                isAuth: false,
            };
        case SET_FAVORITES_DATA:
            return {
                ...state,
                favorites: action.payload,
            };
        case SET_PROMOCODES:
            return {
                ...state,
                promocodes: action.payload,
            };
        case CLEAR_USER_DATA:
            return { ...state, results: action.payload, isLoading: false };
        case DELETE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(
                    (el) => el._id !== action.payload
                ),
            };

        case SET_PERMISSIONS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
