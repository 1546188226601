import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import styles from "../sub-pages/subPages.module.scss";
import CustomerHeader from "components/CustomerHeader/CustomerHeader";
import BackButton from "components/BackButton/BackButton";
import { deleteDiscount, getAllDiscounts } from "store/actions/customer";

function AllDiscounts(props) {
    const { getDiscounts, discounts, deleteCurrent } = props;
    const params = useParams();
    const { id } = params;

    useEffect(() => {
        (async () => {
            await getDiscounts(id);
        })();
        // eslint-disable-next-line
    }, [id]);

    const getData = (date) => {
        if (!date) return "Постоянная";
        const newDate = new Date(date);
        return [
            newDate.getDate(),
            [
                "января",
                "февраля",
                "марта",
                "апреля",
                "мая",
                "июня",
                "июля",
                "августа",
                "сентября",
                "октября",
                "ноября",
                "декабря",
            ][newDate.getMonth()],
            newDate.getFullYear(),
        ].join(" ");
    };

    const renderAll = () => {
        return discounts.map((el) => {
            return (
                <div className={styles.discount} key={el.id}>
                    {el.img.length ? (
                        <img src={`/${el.img[0]}`} alt={el.title} />
                    ) : null}
                    <p>
                        <b>Название:</b> {el.title}
                    </p>
                    <p>
                        <b>Описание</b> {el.description}
                    </p>
                    <p>
                        <b>Осталось купонов:</b> {el.count}
                    </p>
                    <p>
                        <b>Взято купонов:</b> {el?.users?.length || 0}
                    </p>
                    <p>
                        <b>Размер скидки:</b> {el.size}
                    </p>
                    {!el.start && !el.end ? (
                        <p>
                            <b>Длительность</b>: постоянная
                        </p>
                    ) : (
                        <>
                            <p>
                                <b>Начало акции:</b> {getData(el.start)}
                            </p>
                            <p>
                                <b>Конец акции:</b> {getData(el.end)}
                            </p>
                        </>
                    )}
                    <Link
                        className={styles.btn}
                        to={{
                            pathname: "/customer/dashboard/edit",
                        }}
                        state={{
                            data: {
                                ...el,
                                start: el.start,
                                end: el.end,
                            },
                            id,
                        }}
                    >
                        Редактировать
                    </Link>

                    <button
                        onClick={() => {
                            deleteCurrent(el._id);
                        }}
                        className={styles.btn + " " + styles.btnRed}
                    >
                        Удалить
                    </button>
                </div>
            );
        });
    };

    return (
        <>
            <CustomerHeader />
            <div className={styles.container}>
                <h3 className={styles.titleCustomer}>Текущие акции</h3>
                <BackButton />
                <div className={styles.discountPage}>
                    {discounts.length > 0 ? (
                        renderAll()
                    ) : (
                        <h4>У вас пока нету акций</h4>
                    )}
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (store) => {
    return {
        discounts: store.customer.discounts.current,
        store: store.customer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDiscounts: (id) => dispatch(getAllDiscounts(id, "current")),
        deleteCurrent: (id) => dispatch(deleteDiscount(id, "current")),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllDiscounts);
