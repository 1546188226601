import axios from "axios";

export default class ApiService {
    getData = async (url, data) => {
        const res = await axios.post(url, data).catch(function (error) {
            if (error.response) {
                return error.response;
            }
        });
        return res;
    };

    getDataWithGet = async (url) => {
        const res = await axios.get(url).catch(function (error) {
            if (error.response) {
                return error.response;
            }
        });
        return res;
    };

    getDataWithGetParams = async (url, data) => {
        const res = await axios
            .get(url, { params: data })
            .catch(function (error) {
                if (error.response) {
                    return error.response;
                }
            });
        return res;
    };

    sendSignUp = async (data) => {
        const res = await this.getData("/auth/reg", data);
        return this._transformResponse(res);
    };

    getPlace = async (data) => {
        const res = await this.getData("/places/find", data);
        return res.data;
    };

    getCoupons = async (data) => {
        const res = await this.getData("/users/coupons", data);
        return res.data;
    };

    getWishes = async (data) => {
        const res = await this.getDataWithGetParams("/users/wishlist", data);
        return res.data;
    };

    checkAuthDataCustomer = async (data) => {
        const res = await this.getData("/auth/login", data);
        return res;
    };

    checkAuthCustomer = async (data) => {
        const res = await this.getDataWithGet("/auth/login", data);
        return res;
    };

    addNewDiscount = async (data) => {
        const res = await this.getData("/discounts", data);
        return res;
    };

    editDiscount = async (data) => {
        const res = await axios.put("/discounts", data).catch(function (error) {
            if (error.response) {
                return error.response;
            }
        });
        return res;
    };

    uploadPlaceImage = async (data) => {
        const res = await this.getData("/customer/uploadImage", data);
        return res;
    };

    uploadPreview = async (data) => {
        const res = await this.getData("/customer/uploadPreview", data);
        return res;
    };

    getAllImagesForPlace = async (id) => {
        const res = await this.getDataWithGet(`/customer/place/${id}/images`);
        return res;
    };

    deletePlaceImage = async (data) => {
        const res = await this.getDataWithGetParams(
            "/customer/deleteImage",
            data
        );
        return res;
    };

    updateDescription = async (data) => {
        const res = await this.getData("/customer/updateDescription", data);
        return res;
    };

    addWish = async (data) => {
        const res = await this.getDataWithGetParams(
            "/users/wishlist/add",
            data
        );
        return res;
    };

    removeWish = async (data) => {
        const res = await this.getDataWithGetParams(
            "/users/wishlist/delete",
            data
        );
        return res;
    };

    createPlace = async (data) => {
        const res = await this.getData("/customer/createPlace", data);
        return res;
    };

    getCurrentCityId = async (id) => {
        const res = await this.getDataWithGet(`/city/place/${id}`);
        return res;
    };

    _transformResponse = (res) => {
        return {
            status: res.status,
            data: res.data,
        };
    };
}
