import {
    SET_AVAILABLE_CITIES,
    SET_CITY_REQUEST,
    SET_CITY_SUCCESS,
    SET_CITY_SUCCESS_ADD_MAP,
} from "../actions/city";

const initialState = {
    city: "",
    selected: [],
    available: [],
    current: "",
};

export const cityReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CITY_REQUEST:
            return { ...state };
        case SET_CITY_SUCCESS:
            const availableCity = state.available.includes(action.payload)
                ? action.payload
                : state.city;
            return {
                ...state,
                city: availableCity,
            };
        case SET_CITY_SUCCESS_ADD_MAP:
            const city = state.available.find(
                (city) => city.name === action.payload
            );
            if (!city) return state;
            return {
                ...state,
                city: city.name,
                selected: city.coordinates,
            };
        case SET_AVAILABLE_CITIES:
            return {
                ...state,
                available: action.payload,
            };
        default:
            return state;
    }
};
