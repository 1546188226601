export const DAYS = [
    {
        title: "Понедельник",
        value: "Monday",
    },
    {
        title: "Вторник",
        value: "Tuesday",
    },
    {
        title: "Среда",
        value: "Wednesday",
    },
    {
        title: "Четверг",
        value: "Thursday",
    },
    {
        title: "Пятница",
        value: "Friday",
    },
    {
        title: "Суббота",
        value: "Saturday",
    },
    {
        title: "Воскресенье",
        value: "Sunday",
    },
];
