import React from "react";
import { FormControlLabel, styled, Switch, TextField } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import styles from "./subPages.module.scss";
import ImageUploader from "components/ImageUploader/ImageUploader";
import DaysPicker from "components/DaysPicker/DaysPicker";

const CustomInput = styled(TextField)({
    "& label.Mui-focused": {
        color: "green",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#00d07a",
    },
    "& .MuiOutlinedInput-root": {
        paddingLeft: 10,
        marginBottom: 10,

        "& fieldset": {
            borderColor: "#00d07a",
        },
        "&:hover fieldset": {
            borderColor: "#00d07a",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#00d07a",
        },
    },
});

function FormDiscount(props) {
    const { data, setData, setImg } = props;

    return (
        <form className={styles.addDiscount} encType="multipart/form-data">
            <ImageUploader onUpload={setImg} defaultItems={data?.img || []} />
            <div className={styles.nav}>
                <FormControlLabel
                    control={
                        <Switch
                            onChange={() => {
                                setData({
                                    ...data,
                                    isPermanent: !data.isPermanent,
                                });
                            }}
                            color="primary"
                            checked={data.isPermanent}
                        />
                    }
                    label="Постоянная акция"
                    labelPlacement="start"
                    sx={{
                        justifyContent: "start",
                        padding: 0,
                        margin: 0,
                    }}
                />
                <FormControlLabel
                    control={
                        <Switch
                            onChange={() => {
                                setData({
                                    ...data,
                                    isFullWeek: !data.isFullWeek,
                                });
                            }}
                            color="primary"
                            checked={data.isFullWeek}
                        />
                    }
                    label="Все дни недели"
                    labelPlacement="start"
                    sx={{
                        justifyContent: "start",
                        padding: 0,
                        margin: 0,
                        marginLeft: 8,
                    }}
                />
            </div>
            <div className={styles.formDiscount}>
                <div className={styles.column}>
                    <CustomInput
                        label="Название акции"
                        id="custom-css-outlined-input"
                        value={data.title}
                        InputLabelProps={{
                            style: {
                                fontSize: 17,
                            },
                        }}
                        onChange={(e) => {
                            setData({ ...data, title: e.target.value });
                        }}
                    />

                    <CustomInput
                        label="Описание"
                        InputLabelProps={{
                            style: {
                                fontSize: 17,
                            },
                        }}
                        value={data.description}
                        onChange={(e) => {
                            setData({
                                ...data,
                                description: e.target.value,
                            });
                        }}
                        rows={3}
                        multiline
                    />

                    <CustomInput
                        label="Количество купонов"
                        type="number"
                        placeholder="5"
                        min="5"
                        InputLabelProps={{
                            style: {
                                fontSize: 17,
                            },
                        }}
                        defaultValue={data.count}
                        onChange={(e) => {
                            setData({ ...data, count: e.target.value });
                        }}
                    />
                </div>
                <div className={styles.column}>
                    <CustomInput
                        label="Размер скидки"
                        type="number"
                        placeholder="0"
                        min="0"
                        InputLabelProps={{
                            style: {
                                fontSize: 17,
                            },
                        }}
                        defaultValue={data.size}
                        onChange={(e) => {
                            setData({ ...data, size: e.target.value });
                        }}
                    />
                    {!data.isPermanent ? (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDateTimePicker
                                label="Дата начала"
                                value={data.start || null}
                                onChange={(value) => {
                                    setData({ ...data, start: value });
                                }}
                                inputFormat="dd/MM/yyyy HH:mm"
                                color="green"
                                renderInput={(params) => (
                                    <CustomInput
                                        {...params}
                                        label="Дата начала"
                                        defaultValue="2017-05-24T10:30"
                                        sx={{
                                            width: 350,
                                            marginTop: 3,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />

                            <MobileDateTimePicker
                                label="Дата окончания"
                                value={data.end || null}
                                onChange={(value) => {
                                    setData({ ...data, end: value });
                                }}
                                color="green"
                                inputFormat="dd/MM/yyyy HH:mm"
                                ampm={false}
                                renderInput={(params) => (
                                    <CustomInput
                                        {...params}
                                        label="Дата окончания"
                                        defaultValue="2017-05-24T10:30"
                                        sx={{
                                            width: 350,
                                            marginTop: 3,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    ) : null}
                </div>
                <div className={styles.column}>
                    {!data.isFullWeek ? (
                        <DaysPicker
                            updateDays={(days) => {
                                setData({
                                    ...data,
                                    days,
                                });
                            }}
                            updateSlots={(timeslots) => {
                                setData({
                                    ...data,
                                    timeslots,
                                });
                            }}
                            days={data.days}
                            slots={data.timeslots}
                            defaultDuration={data.timeslots.length > 0}
                        />
                    ) : null}
                </div>
            </div>
        </form>
    );
}

export default FormDiscount;
