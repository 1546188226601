import React, { useMemo } from "react";
import { InputAdornment, styled, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";

const CustomInput = styled(TextField)({
    "& label.Mui-focused": {
        color: "#b797f8",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#b797f8",
    },
    "& .MuiOutlinedInput-root": {
        paddingLeft: 10,
        marginBottom: 10,

        "& fieldset": {
            borderColor: "#b797f8",
        },
        "&:hover fieldset": {
            borderColor: "#9d70f7",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#9d70f7",
        },
    },
});

function Text(props) {
    const {
        value = "",
        fontSize = 17,
        label = "",
        cb = () => {},
        width = "100%",
        type = "text",
        required = false,
        marginBottom = 1
    } = props;

    const [visible, setVisible] = useState(false);

    const icon = useMemo(
        () =>
            type === "password" ? (
                <InputAdornment position="end">
                    {!visible ? (
                        <Visibility
                            onClick={() => {
                                setVisible(!visible);
                            }}
                            sx={{ cursor: "pointer" }}
                        />
                    ) : (
                        <VisibilityOff
                            onClick={() => {
                                setVisible(!visible);
                            }}
                            sx={{ cursor: "pointer" }}
                        />
                    )}
                </InputAdornment>
            ) : null,
        [type, visible]
    );

    return (
        <>
            <CustomInput
                InputLabelProps={{
                    style: {
                        fontSize,
                    },
                }}
                onChange={(e) => {
                    cb(e.target.value);
                }}
                label={label}
                value={value}
                type={visible ? "text" : type}
                sx={{ width, marginBottom }}
                required={required}
                InputProps={{
                    endAdornment: icon,
                }}
            />
        </>
    );
}

export default Text;
