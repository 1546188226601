import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import {
    Map,
    withYMaps,
    Placemark,
    Clusterer,
    GeolocationControl,
} from "react-yandex-maps";
import { usePosition } from "../../hooks/usePosition";
import { getCityForCurrentPosition } from "../../store/actions/city";
import zeromarker from "../../assets/images/sale-zero.png";
import goodmarker from "../../assets/images/sale-good.png";
import maxmarker from "../../assets/images/sale-max.png";
import excelentmarker from "../../assets/images/sale-excelent.png";
import styles from "./MainMap.module.scss";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

const YMapsModules = ["geolocation", "geocode"];

const MapsComponent = React.memo(
    ({ ymaps, onChangeCity, places, setCenterCity, routeToPlace }) => {
        const [positionNow, setPositionNow] = useState([]);
        const { position, error } = usePosition();
        const [yandexPosition, setPosition] = useState(() => null);
        const navigate = useNavigate();

        const load = useCallback(() => {
            ymaps.geolocation.geocode
                .get({
                    provider: "yandex",
                    mapStateAutoApply: true,
                    autoReverseGeocode: true,
                })
                .then((result) => {
                    setPosition(result.geoObjects.position);
                    onChangeCity(result.geoObjects.position, ymaps);
                });
        }, [onChangeCity, ymaps]);

        useEffect(() => {
            if (error) {
                load();
            }
        }, [error, load]);

        useEffect(() => {
            if (position) {
                setPosition([position.latitude, position.longitude]);
                load();
            }
        }, [position, load]);

        useEffect(() => {
            setCenterCity.length > 0
                ? setPositionNow(setCenterCity)
                : setPositionNow(yandexPosition);
        }, [setCenterCity, yandexPosition]);

        return RenderMap(positionNow, places, navigate);
    }
);

const RenderMap = (position, places, n) => {
    const renderMap = useMemo(
        () => (
            <Map
                className={styles.map}
                state={{
                    center: position,
                    zoom: 12,
                    controls: [],
                }}
            >
                <Clusterer modules={["clusterer.addon.balloon"]} options={{}}>
                    {getPlacemarksForPlaces(places, n)}
                </Clusterer>
                <GeolocationControl
                    options={{
                        visible: true,
                        float: "right",
                    }}
                />
            </Map>
        ),
        [places, n, position]
    );
    return position && renderMap;
};

const YMapsComponent = withYMaps(MapsComponent, true, [YMapsModules]);

const getCurrentSaleMarker = (sale) => {
    if (sale > 40) {
        return maxmarker;
    } else if (sale > 20) {
        return excelentmarker;
    } else if (sale > 10) {
        return goodmarker;
    } else {
        return zeromarker;
    }
};

const getPlacemarksForPlaces = (places, func) => {
    return places.map((place) => {
        return (
            <Placemark
                key={place._id}
                modules={["geoObject.addon.balloon", "layout.ImageWithContent"]}
                geometry={place.mapPoint}
                iconContent="120"
                options={{
                    iconLayout: "default#image",
                    iconImageHref: getCurrentSaleMarker(place.sale),
                    iconImageSize: [46, 60],
                    iconImageOffset: [-24, -24],
                }}
                properties={{
                    balloonContentHeader: place.title,
                }}
                onBalloonOpen={() => {
                    func(`/place/${place._id}`);
                }}
                onBalloonClose={() => {
                    func("");
                }}
            />
        );
    });
};

function MainMap(props) {
    const { setCityAction, places, city, openPlace } = props;
    return (
        <div className={styles.mapWrapper}>
            <YMapsComponent
                setCenterCity={city}
                onChangeCity={setCityAction}
                places={places}
                routeToPlace={openPlace}
            />
        </div>
    );
}

const mapStateToProps = (store) => {
    return {
        city: store.city.selected,
        places: store.places.list,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCityAction: (location, ymaps) =>
            dispatch(getCityForCurrentPosition(location, ymaps)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMap);
