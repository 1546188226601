import {
    Fastfood,
    Restaurant,
    Checkroom,
    LocalCarWash,
    Storefront,
    CarRepair,
    LocalPharmacy,
    BakeryDining,
    LocalBar,
    LocalHotel,
    LocalCafe,
    Spa,
    OutdoorGrill,
    Agriculture,
    Phishing,
    SportsTennis,
    Carpenter,
    Cable,
} from "@mui/icons-material";

import "./CategoryList.scss";

const categories = [
    {
        name: "Фастфуд",
        icon: <Fastfood className="category__icon" />,
    },
    {
        name: "Ресторан",
        icon: <Restaurant className="category__icon" />,
    },
    {
        name: "Продукты",
        icon: <Storefront className="category__icon" />,
    },
    {
        name: "Одежда",
        icon: <Checkroom className="category__icon" />,
    },
    {
        name: "Автомойка",
        icon: <LocalCarWash className="category__icon" />,
    },
    {
        name: "Автосервис",
        icon: <CarRepair className="category__icon" />,
    },
    {
        name: "Аптека",
        icon: <LocalPharmacy className="category__icon" />,
    },
    {
        name: "Бакалея",
        icon: <BakeryDining className="category__icon" />,
    },
    {
        name: "Бар",
        icon: <LocalBar className="category__icon" />,
    },
    {
        name: "Гостиница",
        icon: <LocalHotel className="category__icon" />,
    },
    {
        name: "Дом и сад",
        icon: <OutdoorGrill className="category__icon" />,
    },
    {
        name: "Кафе",
        icon: <LocalCafe className="category__icon" />,
    },
    {
        name: "Красота",
        icon: <Spa className="category__icon" />,
    },
    {
        name: "Хозтовары",
        icon: <Agriculture className="category__icon" />,
    },
    {
        name: "Рыбалка",
        icon: <Phishing className="category__icon" />,
    },
    {
        name: "Спорттовары",
        icon: <SportsTennis className="category__icon" />,
    },
    {
        name: "Стройматериалы",
        icon: <Carpenter className="category__icon" />,
    },
    {
        name: "Электроника",
        icon: <Cable className="category__icon" />,
    },
];

export default categories;
