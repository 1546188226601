import * as React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { connect } from "react-redux";
import SearchItem from "./SearchItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMemo } from "react";
import { searchWithPagination, setSearchKeywords } from "store/actions/places";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function SearchPage(props) {
    const { keyword, city, results, getMoreItems, setKey } = props;
    const [value, setValue] = React.useState(0);
    const [search] = useSearchParams();
    const [page, setPage] = useState(1);

    useEffect(() => {
        const keys = search.get("keywords");
        if (keys) {
            setKey(keys);
        }
    }, [setKey, search]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const resultsItems = useMemo(() => {
        const findImg = (disc) => {
            if (disc.img && disc.img[0]) return disc.img[0];
            if (disc.place?.img && disc.place.img[0]) return disc.place.img[0];
        };

        return results.map((item) => (
            <SearchItem
                key={item.title}
                title={item.title}
                path={item.place ? item.place._id : item._id}
                customer={item.place?.title}
                img={findImg(item)}
            />
        ));
    }, [results]);

    const fetchData = async () => {
        await getMoreItems({ city, page, key: keyword });
        setPage(page + 1);
    };

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Все совпадения" {...a11yProps(0)} />
                        <Tab label="Акции" {...a11yProps(1)} />
                        <Tab label="Заведения" {...a11yProps(2)} />
                    </Tabs>
                </Box>
            </Box>
            <div id="scrollable" className="search__results">
                <InfiniteScroll
                    dataLength={resultsItems.length}
                    next={fetchData}
                    hasMore={true}
                    scrollableTarget="scrollable"
                >
                    {resultsItems}
                </InfiniteScroll>
            </div>
        </>
    );
}

const mapStateToProps = (store) => ({
    results: store.places.search.results,
    city: store.city.city,
    keyword: store.places.search.keywords,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getMoreItems: (data) => dispatch(searchWithPagination(data)),
        setKey: (key) => dispatch(setSearchKeywords(key)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
