import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
    getPlacesForCurrentUser,
    setCustomerPlace,
} from "store/actions/customer";
import { logout } from "store/actions/user";
import { Select, MenuItem, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";

import styles from "./CustomerHeader.module.scss";
import { isEmpty } from "lodash";

const CustomSelect = styled(Select)`
    background: #fff;
`;

function CustomerHeader(props) {
    const { user, place, places, getAllPlaces, setPlace } = props;

    let navigate = useNavigate();

    useEffect(() => {
        if (user.id) {
            if (places.length === 0) {
                getAllPlaces(user.id);
                return;
            }

            if (isEmpty(place)) setPlace(places[0]._id);
        } else {
            navigate("/customer/");
        }
        // eslint-disable-next-line
    }, [places.length, user.id]);

    const handleInputChange = (e) => {
        setPlace(e.target.value);
    };

    const renderItem = () => {
        return places.map((place) => (
            <MenuItem key={place._id} value={place._id}>
                {place.title}
            </MenuItem>
        ));
    };

    return (
        <div className={styles.ellipse}>
            <div className={styles.container}>
                <div className={styles.select}>
                    {places.length !== 0 && (
                        <CustomSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={place._id ? place._id : ""}
                            onChange={handleInputChange}
                            fullWidth
                        >
                            {renderItem()}
                        </CustomSelect>
                    )}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (store) => {
    return {
        places: store.customer.places,
        place: store.customer.current,
        user: store.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllPlaces: (id) => dispatch(getPlacesForCurrentUser(id)),
        userLogout: (data) => dispatch(logout()),
        setPlace: (place) => dispatch(setCustomerPlace(place)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerHeader);
