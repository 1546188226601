import React from "react";
import { Avatar } from "@mui/material";
import { capitalize } from "lodash";
import { Link } from "react-router-dom";
import "./SearchPage.scss";

function SearchItem(props) {
    const { title, img, customer, path, close } = props;
    return (
        <Link
            key={title}
            to={{
                pathname: `/place/${path}`,
            }}
            className="search-item"
            onClick={() => close()}
        >
            <Avatar
                src={img}
                sx={{
                    width: 50,
                    height: 50,
                    backgroundColor: "#f49df4",
                    fontSize: 15,
                }}
            >
                {capitalize(title[0])}
            </Avatar>
            <div className="search-item__container">
                <h3
                    className={`search-item__title ${
                        !customer ? "search-item__title-bold" : ""
                    }`}
                >
                    {title}
                </h3>
                {customer && (
                    <p className="search-item__description">
                        <span>Заведение:</span> {customer}
                    </p>
                )}
            </div>
        </Link>
    );
}

export default SearchItem;
