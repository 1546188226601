import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";

import MainPage from "Pages/MainPage";
// import CustomerPage from "Pages/CustomerPage";
// import CustomerPlace from "Pages/CustomerCabinet/CustomerPlace/CustomerPlace";
import { userFromLocalStorage } from "../../store/actions/user";
import CustomerCabinet from "Pages/CustomerCabinet/CustomerCabinet";
import LoginCustomer from "Pages/LoginCustomer/LoginCustomer";
import AddDiscount from "../../Pages/CustomerCabinet/CustomerPlace/sub-pages/addDiscount";
import EditDiscount from "../../Pages/CustomerCabinet/CustomerPlace/sub-pages/editDiscount";
import RepeatDiscount from "../../Pages/CustomerCabinet/CustomerPlace/sub-pages/repeatDiscount";
import EditImages from "../../Pages/CustomerCabinet/CustomerPlace/sub-pages/editImages";
import AllDiscounts from "../../Pages/CustomerCabinet/CustomerPlace/sub-pages/allDiscounts";
import EditDesc from "../../Pages/CustomerCabinet/CustomerPlace/sub-pages/editDesc";
import LastDiscounts from "../../Pages/CustomerCabinet/CustomerPlace/sub-pages/lastDiscounts";
import ModerationPlaces from "Pages/CustomerCabinet/ModerationPlaces";
import ModerationUsers from "Pages/CustomerCabinet/ModerationUsers";
import SignUpPage from "Pages/SignUpPage";
import LoginPage from "Pages/LoginPage";

function App(props) {
    const { checkCacheUserData } = props;

    useEffect(() => {
        checkCacheUserData();
    }, [checkCacheUserData]);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/sign-up" element={<SignUpPage />} />
                <Route path="/sign-in" element={<LoginPage />} />

                <Route path="/customer" element={<LoginCustomer />} />
                <Route
                    path="/customer/dashboard"
                    element={<CustomerCabinet />}
                />
                <Route
                    path="/customer/dashboard/add/:id"
                    element={<AddDiscount />}
                />
                <Route
                    path="/customer/dashboard/edit/images/:id"
                    element={<EditImages />}
                />
                <Route
                    path="/customer/dashboard/edit/description/:id"
                    element={<EditDesc />}
                />
                <Route
                    path="/customer/dashboard/show/current/:id"
                    element={<AllDiscounts />}
                />
                <Route
                    path="/customer/dashboard/show/last/:id"
                    element={<LastDiscounts />}
                />
                <Route
                    path="/customer/dashboard/repeat/"
                    element={<RepeatDiscount />}
                />
                <Route
                    path="/customer/dashboard/edit/"
                    element={<EditDiscount />}
                />

                <Route
                    path="/customer/moderation/find/places"
                    element={<ModerationPlaces />}
                />

                <Route
                    path="/customer/moderation/find/users"
                    element={<ModerationUsers />}
                />

                {/* <Route
                        path="/customer/admin"
                        element={
                            <CustomerPlace
                                {...props}
                                key={place._id}
                                place={place}
                            />
                        }
                    /> */}
                {/* <Route
                    path="/customer/admin"
                    element={
                        // <CustomerPlace
                        //     {...props}
                        //     key={place._id}
                        //     place={place}
                        // />
                    }
                /> */}
                <Route path="/*" element={<MainPage />} />
            </Routes>
        </BrowserRouter>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkCacheUserData: (data) => dispatch(userFromLocalStorage()),
    };
};

export default connect(null, mapDispatchToProps)(App);
