import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Zoom } from "swiper";

import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Slider.scss";
import Img from "components/formHelpers/Img";

export const Slider = (props) => {
    const { data, reduce, type, classes = "" } = props;

    return (
        <div className="slider">
            <Swiper
                style={{
                    "--swiper-navigation-color": "#000",
                    "--swiper-pagination-color": "#FFF",
                }}
                zoom={true}
                loop={true}
                navigation={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Zoom, Navigation, Pagination]}
                className={`swiper ${
                    reduce ? "swiper__reduce" : null
                } ${classes}`}
            >
                {data.map((el, i) => {
                    return (
                        <SwiperSlide key={el + i} className="swiper__slide">
                            <div className="swiper-zoom-container">
                                {type === "content" ? (
                                    <div className="swiper__content">
                                        <div className="swiper__content-img">
                                            {el.img}
                                        </div>
                                        <h3>{el.text}</h3>
                                    </div>
                                ) : (
                                    <Img src={`/${el}`} alt="" />
                                )}
                            </div>
                        </SwiperSlide>
                    );
                })}

                {!data.length ? (
                    <SwiperSlide className="swiper__slide">
                        <div className="swiper-zoom-container">
                            <Img alt="preview" />
                        </div>
                    </SwiperSlide>
                ) : null}
            </Swiper>
        </div>
    );
};
