import { Storefront } from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";

function Img(props) {
    const { src, alt, isSmall } = props;
    // eslint-disable-next-line
    const [error, setError] = useState(false);

    const style = useMemo(
        () => ({
            width: "100%",
            height: isSmall ? 150 : 280,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }),
        [isSmall]
    );

    useEffect(() => {
        setError(Boolean(!src));
    }, [src]);

    return (
        <>
            {!error ? (
                <img
                    src={src}
                    alt={alt}
                    onError={() => {
                        setError(true);
                    }}
                />
            ) : (
                <div style={style}>
                    <Storefront
                        sx={{ color: "#889ca9", fontSize: isSmall ? 100 : 150 }}
                    />
                </div>
            )}
        </>
    );
}

export default Img;
