import axios from "axios";

export const GET_CUSTOMER_PLACES_REQUEST = "GET_CUSTOMER_PLACES_REQUEST";
export const SET_CUSTOMER_PLACES_SUCCESS = "SET_CUSTOMER_PLACES_SUCCESS";
export const SET_CUSTOMER_CURRENT_PLACE = "SET_CUSTOMER_CURRENT_PLACE";
export const SET_MODERATOR_PLACES_SUCCESS = "SET_MODERATOR_PLACES_SUCCESS";
export const SET_MODERATOR_USERS_SUCCESS = "SET_MODERATOR_USERS_SUCCESS";
export const DELETE_MODERATOR_PLACE = "DELETE_MODERATOR_PLACE";
export const DELETE_MODERATOR_USER = "DELETE_MODERATOR_USER";
export const ADD_USER_PERMISSION = "ADD_USER_PERMISSION";
export const SET_ACTIVE_FOR_USER = "SET_ACTIVE_FOR_USER";
export const DELETE_USER_PERMISSION = "DELETE_USER_PERMISSION";
export const SET_DISCOUNTS = "SET_DISCOUNTS";
export const DELETE_DISCOUNT = "DELETE_DISCOUNT";

export function getPlacesForCurrentUser(user) {
    return (dispatch) => {
        dispatch({
            type: GET_CUSTOMER_PLACES_REQUEST,
            payload: user,
        });

        axios
            .get("/customer/places", {
                params: {
                    id: user,
                },
            })
            .then((res) => {
                dispatch({
                    type: SET_CUSTOMER_PLACES_SUCCESS,
                    payload: res.data,
                });
            });
    };
}

export function setCustomerPlaces(places) {
    return (dispatch) => {
        dispatch({
            type: SET_CUSTOMER_PLACES_SUCCESS,
            payload: places,
        });
    };
}

export function setCustomerPlace(place) {
    return (dispatch) => {
        dispatch({
            type: SET_CUSTOMER_CURRENT_PLACE,
            payload: place,
        });
    };
}

export function getPlacesForModeration() {
    return (dispatch) => {
        axios.get("/customer/moderation/places").then((res) => {
            dispatch({
                type: SET_MODERATOR_PLACES_SUCCESS,
                payload: res.data,
            });
        });
    };
}

export function getUsersForModeration(query) {
    return (dispatch) => {
        axios
            .get("/customer/moderation/users", {
                params: {
                    query,
                },
            })
            .then((res) => {
                dispatch({
                    type: SET_MODERATOR_USERS_SUCCESS,
                    payload: res.data,
                });
            });
    };
}

export function approvePlace(payload) {
    const { id, status } = payload;
    return (dispatch) => {
        axios
            .get("/customer/moderation/place", {
                params: {
                    status,
                    id,
                },
            })
            .then((res) => {
                dispatch({
                    type: DELETE_MODERATOR_PLACE,
                    payload: id,
                });
            });
    };
}

export function declinePlace(id) {
    return (dispatch) => {
        axios
            .delete("/customer/moderation/place", {
                params: {
                    id,
                },
            })
            .then((res) => {
                dispatch({
                    type: DELETE_MODERATOR_PLACE,
                    payload: id,
                });
            });
    };
}

export function activateUserById(id) {
    return (dispatch) => {
        axios
            .put("/customer/moderation/user/activate", {
                id,
            })
            .then((res) => {
                dispatch({
                    type: SET_ACTIVE_FOR_USER,
                    payload: id,
                });
            });
    };
}

export function addPermisionsById(payload) {
    const { id, role, cities, expired } = payload;

    return (dispatch) => {
        axios
            .put("/customer/moderation/user/permissions", {
                expired,
                cities,
                role,
                id,
            })
            .then((res) => {
                dispatch({
                    type: ADD_USER_PERMISSION,
                    payload,
                });
            });
    };
}

export function deletePermisionsById(payload) {
    const { id, role } = payload;

    return (dispatch) => {
        axios
            .delete("/customer/moderation/user/permissions", {
                params: {
                    role,
                    id,
                },
            })
            .then((res) => {
                dispatch({
                    type: DELETE_USER_PERMISSION,
                    payload,
                });
            });
    };
}

export function removeUserById(id) {
    return (dispatch) => {
        axios
            .delete("/customer/moderation/user", {
                params: {
                    id,
                },
            })
            .then((res) => {
                dispatch({
                    type: DELETE_MODERATOR_USER,
                    payload: id,
                });
            });
    };
}

export function getAllDiscounts(id, type) {
    return (dispatch) => {
        axios
            .get(`/discounts/${type}`, {
                params: {
                    id,
                },
            })
            .then((res) => {
                dispatch({
                    type: SET_DISCOUNTS,
                    payload: { data: res.data, type },
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function deleteDiscount(id, type) {
    return (dispatch) => {
        axios
            .delete(`/discounts/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_DISCOUNT,
                    payload: { id, type },
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function getPlacesByName(query) {
    return (dispatch) => {
        axios
            .get("/customer/moderation/places", {
                params: {
                    query,
                },
            })
            .then((res) => {
                dispatch({
                    type: SET_MODERATOR_PLACES_SUCCESS,
                    payload: res.data,
                });
            });
    };
}
