import React, { useMemo, useState } from "react";

import { Button, IconButton, TextField } from "@mui/material";

import "./styles.scss";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import moment from "moment";

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

function SingleDay(props) {
    const {
        title,
        checked,
        onSelect,
        value,
        duration,
        timeslots,
        handleTimeslots,
    } = props;

    const [showNewTimeField, setVisibleNewTime] = useState(false);
    const [isCreating, setCreating] = useState(false);
    const [error, setError] = useState("");

    const [newTime, setNewTimestamp] = useState({
        start: null,
        end: null,
    });

    const changeTime = (time, type) => {
        setNewTimestamp((prev) => ({
            ...prev,
            [type]: isValidDate(time) ? time : null,
        }));
    };

    const isCorrectTime = useMemo(() => {
        const hasTimeCrossing = timeslots.some((time) => {
            const isMiddleTime =
                moment(newTime.start).isAfter(time.start) &&
                moment(newTime.end).isBefore(time.end);

            const hasStartBeforePrevious =
                moment(newTime.start).isBefore(time.end) &&
                moment(newTime.start).isAfter(time.start);

            const hasEndInPrevious =
                moment(newTime.end).isAfter(time.start) &&
                moment(newTime.start).isBefore(time.start);

            return isMiddleTime || hasStartBeforePrevious || hasEndInPrevious;
        });

        setError(
            hasTimeCrossing ? "Время имеет пересечение с уже добавлеными" : ""
        );

        return !Boolean(
            newTime.start &&
                newTime.end &&
                moment(newTime.start).isBefore(newTime.end) &&
                !hasTimeCrossing
        );
    }, [newTime, timeslots]);

    const getDurations = () =>
        timeslots.map((time, i) => (
            <div key={i} className="single-day__timeslots-time">
                <p>{`${moment(time.start).format("HH:mm")} - ${moment(
                    time.end
                ).format("HH:mm")}`}</p>
            </div>
        ));

    const updateDurations = () => {
        setVisibleNewTime(false);
        setCreating(false);

        if (handleTimeslots) {
            handleTimeslots({
                start: moment(newTime.start).milliseconds(0).toDate(),
                end: moment(newTime.end).milliseconds(0).toDate(),
            });
        }

        setNewTimestamp({
            start: null,
            end: null,
        });
    };

    return (
        <div className="single-day">
            <Button
                variant={checked ? "contained" : "outlined"}
                sx={{ width: 135, marginBottom: showNewTimeField ? 0 : 2 }}
                onClick={() => onSelect(value)}
            >
                {title}
            </Button>
            <div className="single-day__timeslots">
                {duration && timeslots.length ? getDurations() : null}
            </div>

            {showNewTimeField ? (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div className="single-day__timepicker">
                        <TimePicker
                            clearable
                            value={newTime.start}
                            ampm={false}
                            onChange={(time) => {
                                changeTime(time, "start");
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Начало"
                                    defaultValue="2017-05-24T10:30"
                                    sx={{
                                        width: 120,
                                        fontSize: 12,
                                        marginTop: 3,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                        <TimePicker
                            clearable
                            minTime={newTime.start}
                            value={newTime.end}
                            ampm={false}
                            onChange={(time) => {
                                changeTime(time, "end");
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Конец"
                                    defaultValue="2017-05-24T10:30"
                                    sx={{
                                        width: 120,
                                        marginTop: 3,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                        <IconButton
                            onClick={updateDurations}
                            aria-label="Добавить время"
                            sx={{
                                marginLeft: 2,
                                marginTop: 2,
                            }}
                            disabled={isCorrectTime}
                        >
                            <CheckCircleIcon
                                color="primary"
                                sx={{
                                    transform: "scale(1.2)",
                                    color: isCorrectTime ? "gray" : "#00d07a",
                                }}
                            />
                        </IconButton>
                    </div>
                </LocalizationProvider>
            ) : null}

            {showNewTimeField && error ? (
                <p className="single-day__error">{error}</p>
            ) : null}

            {duration && timeslots.length < 3 ? (
                <Button
                    variant="outlined"
                    className="single-day__btn-time"
                    sx={{
                        width: 160,
                        fontSize: 12,
                        color: "#bdbdbd",
                        borderColor: "#bdbdbd",
                        marginBottom: 2,
                        "&:hover": {
                            borderColor: "#bdbdbd",
                            textDecoration: "underline",
                        },
                    }}
                    onClick={() => {
                        setCreating((prev) => !prev);
                        setVisibleNewTime(!isCreating);
                    }}
                >
                    {isCreating ? "Отменить" : "Добавить время"}
                </Button>
            ) : null}
        </div>
    );
}

export default SingleDay;
