import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./BackButton.module.scss";

function BackButton() {
    const navigate = useNavigate();
    return (
        <button onClick={() => navigate(-1)} className={styles.btn}>
            Вернуться назад
        </button>
    );
}

export default BackButton;
