import React, { useEffect } from "react";
import styles from "./CustomerPlace/sub-pages/subPages.module.scss";
import {
    getPlacesForModeration,
    approvePlace,
    declinePlace,
    getPlacesByName,
} from "store/actions/customer";
import CustomerHeader from "components/CustomerHeader/CustomerHeader";
import BackButton from "components/BackButton/BackButton";
import { connect } from "react-redux";
import {
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions,
    Collapse,
    Avatar,
    IconButton,
    Typography,
    styled,
    TextField,
} from "@mui/material";

import { DoneOutline, ModeEdit, DoDisturb } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Header from "components/Header/Header";
import { useState } from "react";

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

const CustomInput = styled(TextField)({
    "& label.Mui-focused": {
        color: "green",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#00d07a",
    },
    "& .MuiOutlinedInput-root": {
        paddingLeft: 10,
        marginBottom: 10,

        "& fieldset": {
            borderColor: "#00d07a",
        },
        "&:hover fieldset": {
            borderColor: "#00d07a",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#00d07a",
        },
    },
});

function ModerationPlace(props) {
    const { places, getPlacesForModeration, approve, decline, search } = props;

    const [expanded, setExpanded] = React.useState(false);
    const [query, setQuery] = useState("");
    const [isSearch, setSearchVisible] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        (async () => {
            await getPlacesForModeration();
        })();
    }, [getPlacesForModeration]);

    const renderAllPlaces = () => {
        return places.map((place) => (
            <Card sx={{ width: 345, height: "auto" }} key={place._id}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: "#00d07a" }} aria-label="recipe">
                            {place.title[0]}
                        </Avatar>
                    }
                    title={place.title}
                    subheader={place.type}
                />
                <CardMedia
                    component="img"
                    height="194"
                    image={
                        place.img[0]
                            ? `/${place.img[0]}`
                            : "https://previews.123rf.com/images/pavlostv/pavlostv1805/pavlostv180500401/101741080-oops-404-error-page-not-found-futuristic-robot-concept-%C3%A2%E2%82%AC%E2%80%9C-vector.jpg"
                    }
                    alt={place.title}
                />
                <CardActions disableSpacing>
                    {!isSearch && (
                        <>
                            <IconButton
                                aria-label="approve"
                                onClick={() => {
                                    approve({
                                        id: place._id,
                                        status: "active",
                                    });
                                }}
                            >
                                <DoneOutline />
                            </IconButton>
                            <IconButton
                                aria-label="edit"
                                onClick={() => {
                                    approve({ id: place._id, status: "edit" });
                                }}
                            >
                                <ModeEdit />
                            </IconButton>
                        </>
                    )}
                    <IconButton
                        aria-label="share"
                        onClick={() => {
                            decline(place._id);
                        }}
                    >
                        <DoDisturb /> {isSearch && "Удалить"}
                    </IconButton>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography paragraph>
                            <b>Описание:</b> {place.description}
                        </Typography>
                        <Typography paragraph>
                            <b>Город:</b> {place.city}
                        </Typography>
                        <Typography paragraph>
                            <b>Адрес:</b> {place.address}
                        </Typography>
                        <Typography paragraph>
                            <b>Телефон:</b> {place.phone}
                        </Typography>
                        <Typography>
                            <b>Рабочие часы:</b>
                            {` ${place.workHours.start} - ${place.workHours.end}`}
                        </Typography>
                    </CardContent>
                </Collapse>
            </Card>
        ));
    };

    const find = async () => {
        if (query.length > 1) {
            await search(query);
            setSearchVisible(true);
        } else {
            setSearchVisible(false);
        }
    };

    return (
        <>
            <Header />
            <CustomerHeader />

            <div className={styles.container}>
                <div className={styles.searchFlex}>
                    <CustomInput
                        label="Введите название заведения"
                        id="custom-css-outlined-input"
                        value={query}
                        InputLabelProps={{
                            style: {
                                fontSize: 17,
                            },
                        }}
                        onChange={(e) => {
                            setQuery(e.target.value);
                        }}
                        sx={{ width: 340 }}
                    />
                    <button
                        onClick={find}
                        className={styles.btn}
                        style={{ marginTop: 0 }}
                    >
                        Поиск
                    </button>
                </div>

                <h3 className={styles.titleCustomer}>
                    {isSearch
                        ? "Заведения соответсвующие запросу поиска"
                        : "Заведение которые требуют модерации"}
                </h3>
                <div className={styles.flex}>
                    {places.length ? renderAllPlaces() : "Заведения не найдены"}
                </div>
                <BackButton />
            </div>
        </>
    );
}

const mapStateToProps = (store) => {
    return {
        places: store.customer.moderation.places,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPlacesForModeration: () => dispatch(getPlacesForModeration()),
        search: (query) => dispatch(getPlacesByName(query)),
        approve: (id) => dispatch(approvePlace(id)),
        decline: (id) => dispatch(declinePlace(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModerationPlace);
