import React from "react";
import styles from "./Spinner.module.scss";

function Spinner(props) {
  return (
    <div className={styles.spinnerBox}>
      <div className={styles.circleBorder}>
        <div className={styles.circleCore}></div>
      </div>
    </div>
  );
}

export default Spinner;
