import axios from "axios";

export const SET_SEARCH_REQUEST = "SET_SEARCH_REQUEST";
export const SET_SEARCH_SUCCESS = "SET_SEARCH_SUCCESS";

export function getSearchResults(query) {
  return (dispatch) => {
    dispatch({
      type: SET_SEARCH_REQUEST,
      payload: query,
    });

    axios.get("https://swapi.dev/api/planets/").then((res) => {
      dispatch({
        type: SET_SEARCH_SUCCESS,
        payload: res.data.results,
      });
    });
  };
}
