import axios from "axios";

export const SET_CITY_REQUEST = "SET_CITY_REQUEST";
export const SET_CITY_SUCCESS = "SET_CITY_SUCCESS";
export const SET_CITY_SUCCESS_ADD_MAP = "SET_CITY_SUCCESS_ADD_MAP";
export const SET_AVAILABLE_CITIES = "SET_AVAILABLE_CITIES";

export function getCityForCurrentPosition(location, ymaps) {
    return (dispatch) => {
        dispatch({
            type: SET_CITY_REQUEST,
            payload: location,
        });
        ymaps.geolocation.geocode.get(location).then((res) => {
            let firstGeoObject = res.geoObjects.get(0);
            const cityNow = firstGeoObject.getLocalities();
            dispatch({
                type: SET_CITY_SUCCESS,
                payload: cityNow[0],
            });
        });
    };
}

export function replaceCurrentCity(city) {
    return (dispatch) => {
        dispatch({
            type: SET_CITY_SUCCESS_ADD_MAP,
            payload: city,
        });
    };
}

export function fetchAllCities() {
    return (dispatch) => {
        axios.get("/city").then((res) => {
            dispatch({
                type: SET_AVAILABLE_CITIES,
                payload: res.data,
            });
        });
    };
}
