import axios from "axios";

export const GET_PLACES_REQUEST = "GET_PLACES_REQUEST";
export const GET_PLACES_SUCCESS = "GET_PLACES_SUCCESS";
export const SET_FILTERED_SEARCH_RESULTS = "SET_FILTERED_SEARCH_RESULTS";
export const SET_FILTERED_PLACES = "SET_FILTERED_PLACES";
export const ADD_REPLACE_CATEGORIES = "ADD_REPLACE_CATEGORIES";
export const SET_CURRENT_PLACE = "SET_CURRENT_PLACE";
export const SET_CURRENT_DISCOUNTS = "SET_CURRENT_DISCOUNTS";
export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";
export const SET_SEARCH_KEYWORDS = "SET_SEARCH_KEYWORDS";
export const SET_PROMOCODE = "SET_PROMOCODE";

export function getPlacesForCurrentCity(location) {
    return (dispatch) => {
        dispatch({
            type: GET_PLACES_REQUEST,
            payload: location,
        });

        axios
            .get("/places", {
                params: {
                    city: location,
                },
            })
            .then((res) => {
                dispatch({
                    type: GET_PLACES_SUCCESS,
                    payload: res.data,
                });
            });
    };
}

export function getPlaceById(id) {
    return (dispatch) => {
        axios
            .get("/places/find", {
                params: {
                    id,
                },
            })
            .then((res) => {
                dispatch({
                    type: SET_CURRENT_PLACE,
                    payload: res.data,
                });
            });
    };
}

export function getDiscountsByPlace(id, user) {
    return (dispatch) => {
        axios
            .get(`/discounts/available/${id}`, {
                params: {
                    user,
                },
            })
            .then((res) => {
                dispatch({
                    type: SET_CURRENT_DISCOUNTS,
                    payload: res.data,
                });
            });
    };
}

export function setActiveCategories(category) {
    return (dispatch, _state) => {
        dispatch({
            type: ADD_REPLACE_CATEGORIES,
            payload: category,
        });

        const { categories } = _state().places;
        const { city } = _state().city;

        axios
            .get(`/places/findByCategory`, {
                params: {
                    categories: categories.join(","),
                    city,
                },
            })
            .then((res) => {
                dispatch({
                    type: SET_FILTERED_PLACES,
                    payload: res.data.results,
                });
            });
    };
}

export function searchByKeywords({ city, key }) {
    return (dispatch) => {
        axios
            .get("/places/search", {
                params: {
                    city,
                    key,
                },
            })
            .then((res) => {
                dispatch({
                    type: CLEAR_SEARCH_RESULTS,
                });
                dispatch({
                    type: SET_FILTERED_SEARCH_RESULTS,
                    payload: res.data.results,
                });
            });
    };
}

export function setSearchKeywords(key) {
    return (dispatch) => {
        dispatch({
            type: SET_SEARCH_KEYWORDS,
            payload: key,
        });
    };
}

export function searchWithPagination({ city, key, page }) {
    return (dispatch) => {
        axios
            .get("/places/search", {
                params: {
                    city,
                    key,
                    page,
                },
            })
            .then((res) => {
                dispatch({
                    type: SET_FILTERED_SEARCH_RESULTS,
                    payload: res.data.results,
                });
            });
    };
}

export function getPromocodeByUser(id) {
    return async (dispatch) => {
        await axios.post(`/users/promocodes/${id}`).then((res) => {
            dispatch({
                type: SET_PROMOCODE,
                payload: {
                    discount: id,
                    code: res.data.code,
                },
            });
        });
    };
}
