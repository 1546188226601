import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchAllCities, replaceCurrentCity } from "store/actions/city";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import styles from "./CitySelector.module.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function CitySelector(props) {
    const { setNewCity, getAllCities } = props;
    const { available: cities, city: cityFromMap } = props.city;
    const [city, setCity] = useState("");
    const [isEdit, setEdit] = useState(false);
    const localeCity = localStorage.getItem("city");

    useEffect(() => {
        getAllCities();
    }, [getAllCities]);

    useEffect(() => {
        switch (true) {
            case localeCity && localeCity.length > 0:
                setCurrentCity(localeCity);
                setNewCity(localeCity);
                break;
            case cityFromMap !== "" && cities.includes(cityFromMap):
                setCurrentCity(cityFromMap);
                break;
            default:
                if (
                    (!cityFromMap || !cities.includes(cityFromMap)) &&
                    cities.length &&
                    (city !== cityFromMap || cityFromMap === "")
                ) {
                    setCurrentCity(cities[0].name);
                    setNewCity(cities[0].name);
                }
        }

        // eslint-disable-next-line
    }, [cityFromMap, cities, setNewCity]);

    const handleChange = (event) => {
        const {
            target: { value: city },
        } = event;
        setCurrentCity(city);
        setNewCity(city);
        setEdit(false);
    };

    const setCurrentCity = (name) => {
        localStorage.setItem("city", name);
        setCity(name);
    };

    return (
        <div className={styles.citySelector}>
            <h2 className={styles.subTitle}>Вы смотрите скидки для города:</h2>
            <div className={styles.city}>
                {isEdit ? (
                    <Select
                        value={city}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                        sx={{ width: 250, marginTop: 1, marginBottom: 1 }}
                    >
                        {cities.map((city) => (
                            <MenuItem key={city._id} value={city.name}>
                                {city.name}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    <>
                        <h1 className={styles.title}>{city}</h1>
                        <button
                            className={styles.btn}
                            onClick={() => {
                                setEdit(!isEdit);
                            }}
                        >
                            Изменить
                        </button>
                    </>
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (store) => {
    return {
        city: store.city,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setNewCity: (location) => dispatch(replaceCurrentCity(location)),
        getAllCities: () => dispatch(fetchAllCities()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CitySelector);
