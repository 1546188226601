import React, { useState } from "react";
import { connect } from "react-redux";
import { getPermissionsById, logout } from "../../store/actions/user";
import { Link } from "react-router-dom";
import { Favorite, Notifications } from "@mui/icons-material";

import "./UserWidget.scss";
import { Avatar, Tooltip, Popover } from "@mui/material";
import RequirePermissions from "hoc/RequirePermissions";
import { useEffect } from "react";

function UserWidget(props) {
    const { user, userLogout, getPermissions } = props;

    useEffect(() => {
        if (user && user.id) {
            getPermissions(user.id);
        }
        //eslint-disable-next-line
    }, []);

    const [notificationVisible, setNotificationVisible] = useState(null);
    const [userbarVisible, setUserbarVisible] = useState(null);

    const notification_open = Boolean(notificationVisible);
    const notification_id = notification_open ? "simple-popover" : undefined;

    const userbar_open = Boolean(userbarVisible);
    const userbar_id = userbar_open ? "simple-popover" : undefined;

    const quit = () => {
        userLogout();
    };

    return user.isAuth ? (
        <div className="userbar">
            <div className="userbar__buttons">
                <button className="userbar__buttons-favorite">
                    <Tooltip title="Ваши любимые заведения">
                        <Link to="/me/favorite">
                            <Favorite />
                        </Link>
                    </Tooltip>
                </button>
                <button className="userbar__buttons-notification">
                    <Tooltip title="Уведомления">
                        <Notifications
                            aria-describedby={notification_id}
                            onClick={(e) =>
                                setNotificationVisible(e.currentTarget)
                            }
                        />
                    </Tooltip>

                    <Popover
                        id={notification_id}
                        open={notification_open}
                        anchorEl={notificationVisible}
                        onClose={() => setNotificationVisible(null)}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        The content
                    </Popover>

                    {user.notifications.length ? (
                        <span>{user.notifications.length}</span>
                    ) : null}
                </button>
            </div>
            <button
                className="userbar__user"
                aria-describedby={userbar_id}
                onClick={(e) => setUserbarVisible(e.currentTarget)}
            >
                <Avatar
                    src={`/${user.avatar}`}
                    sx={{
                        width: 40,
                        height: 40,
                        backgroundColor: "#f49df4",
                        border: "3px solid #f49df4",
                        fontSize: 15,
                    }}
                >
                    {user.name[0]}
                </Avatar>
                <p>{user.name}</p>
            </button>
            <Popover
                id={userbar_id}
                open={userbar_open}
                anchorEl={userbarVisible}
                onClose={() => setUserbarVisible(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <ul className="userbar__menu">
                    {/* <li className="userbar__menu-item">
                        <Link to="/me">Профиль</Link>
                    </li> */}
                    <li className="userbar__menu-item">
                        <Link to="/me/promocodes">Мои промокоды</Link>
                    </li>
                    <RequirePermissions any={["manager", "owner"]}>
                        <li className="userbar__menu-item">
                            <Link to="/customer/dashboard">
                                Панель администратора
                            </Link>
                        </li>
                    </RequirePermissions>
                    <li className="userbar__menu-item" onClick={quit}>
                        Выйти
                    </li>
                </ul>
            </Popover>
        </div>
    ) : (
        <div className="signbar__buttons">
            <Link className="signbar__buttons-signup" to={"/sign-up"}>
                Зарегистрироваться
            </Link>
            <Link className="signbar__buttons-signin" to={"/sign-in"}>
                Войти
            </Link>
        </div>
    );
}

const mapStateToProps = (store) => {
    return {
        user: store.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userLogout: (data) => dispatch(logout()),
        getPermissions: (id) => dispatch(getPermissionsById(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserWidget);
