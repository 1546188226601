import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./styles.scss";

function ImageUploader({ onUpload, immediatly, defaultItems = [] }) {
    const [files, setFiles] = useState([]);
    const [uniq, setUniq] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/*": [],
        },
        multiple: true,
        onDrop: (acceptedFiles) => {
            setFiles((prev) => {
                const _files = acceptedFiles
                    .filter((el) => !uniq.includes(el.name))
                    .map((file) => {
                        setUniq((prev) => [...prev, file.name]);
                        return Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        });
                    });
                return [...prev, ..._files];
            });
        },
    });

    const renderDefaults = defaultItems.map((img) => {
        return (
            <div className="thumb" key={`${img}-preview`}>
                <div className="thumbInner">
                    <img src={`/${img}`} alt="preview" />
                </div>
            </div>
        );
    });

    const thumbs = files.map((file) => (
        <div className="thumb" key={file.name}>
            <div className="thumbInner">
                <img
                    src={file.preview}
                    onLoad={() => {
                        URL.revokeObjectURL(file.preview);
                    }}
                    alt="preview"
                />
            </div>
        </div>
    ));

    useEffect(() => {
        return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        onUpload(files);
        if (immediatly && files.length) setFiles([]);
    }, [files, immediatly, onUpload]);

    return (
        <section className="uploader-container">
            <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>Перетащите сюда фотографии или кликните для выбора...</p>
            </div>
            <aside className="thumbsContainer">
                {defaultItems.length ? renderDefaults : null}
                {thumbs}
            </aside>
        </section>
    );
}

export default ImageUploader;
