import { connect } from "react-redux";

function RequirePermissions(props) {
    const { permissions, any, all } = props;

    const toArray = (value) => {
        if (value.constructor === String) {
            value = [value];
        }
        return value;
    };

    const check = (perm, all) => {
        if (perm[0] === "!" || perm[0] === "~") {
            return all.indexOf(perm.substr(1)) === -1;
        }
        return all.indexOf(perm) !== -1;
    };

    const canAll = () => {
        return toArray(all).every((perm) => {
            return check(perm, permissions);
        });
    };

    const canAny = () => {
        return toArray(any).some((perm) => {
            return check(perm, permissions);
        });
    };

    const checkPermissions = () => {
        if (any) return canAny();
        if (all) return canAll();
        return true;
    };

    return checkPermissions() ? props.children : null;
}

const mapStateToProps = (store) => {
    return {
        permissions: store.user.permissions,
    };
};

export default connect(mapStateToProps)(RequirePermissions);
