import React, { useState } from "react";
import ApiService from "services/api-service";
import { useLocation } from "react-router-dom";
import FormDiscount from "./formDiscount";
import styles from "./subPages.module.scss";
import BackButton from "components/BackButton/BackButton";
import CustomerHeader from "components/CustomerHeader/CustomerHeader";

function RepeatDiscount(props) {
    const params = useLocation();
    const { id, data } = params.state;
    const service = new ApiService();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [sale, setSale] = useState(data);
    const [imgData, setImgData] = useState(null);

    const submit = async () => {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("title", sale.title);
        formData.append("start", sale.start);
        formData.append("end", sale.end);
        formData.append("size", sale.size);
        formData.append("count", sale.count);
        formData.append("description", sale.description);
        if (imgData) {
            for (var i = 0; i < imgData.length; i++) {
                formData.append("file_" + i, imgData[i]);
            }
        }
        setLoading(true);
        const res = await service.addNewDiscount(formData);
        setLoading(false);
        if (res.status === 200) {
            setMessage("Акция была успешно добавлена");
        }
    };

    const valid = () => {
        if (loading) return false;
        const checkData = Object.values(sale).some(
            (el) => el === 0 || el === ""
        );
        setError(checkData);
        return !checkData;
    };

    return (
        <>
            <CustomerHeader />
            <div className={styles.container}>
                <h3 className={styles.titleCustomer}>Повторить акцию</h3>
                {error && (
                    <div className={styles.error}>
                        Заполните все текстовые поля
                    </div>
                )}
                {message !== "" ? (
                    <div className={styles.notification}>{message}</div>
                ) : null}
                <FormDiscount
                    data={sale}
                    setData={setSale}
                    setImg={setImgData}
                    imgData={imgData}
                />
                {message === "" ? (
                    <button
                        onClick={() => {
                            if (valid()) {
                                submit();
                            }
                        }}
                        className={styles.btn}
                    >
                        Добавить акцию
                    </button>
                ) : null}
                <BackButton />
            </div>
        </>
    );
}

export default RepeatDiscount;
