import React, { useState } from "react";
import "./CookiesModal.scss";
import { Link } from "react-router-dom";

function CookiesModal(props) {
    const [visible, setVisible] = useState(true);

    const accept = () => {
        localStorage.setItem("privacy-accept", true);
        setVisible(false);
    };

    if (!visible) return null;

    return (
        <div className="cookies-modal">
            <h2>Наше использование файлов cookie</h2>
            <p>
                Мы используем файлы cookie, в том числе файлы cookie третьих
                сторон, чтобы улучшить ваш опыт и показывать вам
                персонализированный контент.
            </p>
            <p>
                Чтобы узнать больше, ознакомьтесь с нашей{" "}
                <Link to="/privacy">
                    Политикой конфиденциальности и Политикой использования
                    файлов cookie
                </Link>
            </p>
            <button onClick={accept}>Принять все</button>
        </div>
    );
}

export default CookiesModal;
