import Place from "components/Place/Place";
import React from "react";
import { connect } from "react-redux";
import "./PlacesList.scss";

function PlacesList(props) {
    const { places } = props;

    return places.length !== 0 ? (
        <div className="placeList">
            {places.map((place) => (
                <Place
                    id={place._id}
                    key={place._id}
                    title={place.title}
                    address={place.address}
                    img={place.preview}
                    sale={place.sale}
                    views={place.views}
                    gotCoupons={place.gotCoupons}
                />
            ))}
        </div>
    ) : (
        <div className="notFound">
            <img src={require("assets/images/cat.png")} alt="" />
            <p>Скидки по Вашему запросу не найдены</p>
        </div>
    );
}

const mapStateToProps = (store) => {
    return {
        city: store.city,
        places: store.places.list,
    };
};

export default connect(mapStateToProps)(PlacesList);
