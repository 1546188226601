import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { checkAuth, userFromLocalStorage } from "../../store/actions/user";
import styles from "./LoginCustomer.module.scss";

function LoginCustomer(props) {
    const { sendAuthData, user, checkCacheUserData } = props;
    const [authData, setAuthData] = useState({
        email: "",
        password: "",
        type: "customer",
    });
    const [error, setError] = useState("");

    const checkAuthData = async (e) => {
        e.preventDefault();
        if (!authData.email.length) {
            setError(
                "Поле 'E-mail' не может быть пустым - пожалуйста заполните"
            );
            return;
        }

        if (!authData.password.length) {
            setError(
                "Поле 'Пароль' не может быть пустым - пожалуйста заполните"
            );
            return;
        }
        await sendAuthData(authData);
    };

    useEffect(() => {
        checkCacheUserData()
    }, [checkCacheUserData])

    return (
        <div className={styles.main}>
            {user.id && <Navigate to="/customer/dashboard" replace={true} />}
            <div className={styles.illustration}>
                {/* <img src={Logo} alt="logo" /> */}
            </div>
            <div className={styles.container}>
                <h2>Личный кабинет</h2>
                <p>
                    Для входа используйте данные которые дал Вам представитель
                    "Поля"
                </p>
                {error.length > 0 && (
                    <div className={styles.error}>{error}</div>
                )}
                <form>
                    <label>E-mail</label>
                    <input
                        type="text"
                        onChange={(e) => {
                            setError("");
                            setAuthData({ ...authData, email: e.target.value });
                        }}
                    ></input>
                    <label>Пароль</label>
                    <input
                        type="password"
                        onChange={(e) => {
                            setError("");
                            setAuthData({
                                ...authData,
                                password: e.target.value,
                            });
                        }}
                    ></input>
                    <button onClick={checkAuthData}>Войти</button>
                </form>
            </div>
        </div>
    );
}

const mapStateToProps = (store) => {
    return {
        user: store.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendAuthData: (data) => dispatch(checkAuth(data)),
        checkCacheUserData: (data) => dispatch(userFromLocalStorage())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginCustomer);
