import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { searchByKeywords } from "../../store/actions/places";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import "./Search.scss";

function Search(props) {
    const { city, search } = props;
    const [keywords, setKeywords] = useState("");

    const navigate = useNavigate();
    const location = useLocation();

    const onSearch = useCallback(
        async (key) => {
            await search({ key, city });
        },
        //eslint-disable-next-line
        [city]
    );

    //eslint-disable-next-line
    const setSearch = useCallback(debounce(onSearch, 1000), [city]);

    const handleKeyDown = (e) => {
        if (e.key !== "Enter") return;
    };

    const setNavigate = (key) => {
        if (location.pathname !== "/search" && key.length > 0)
            navigate({
                pathname: "search",
                search: createSearchParams({
                    keywords: key,
                }).toString(),
            });
    };

    return (
        <div
            className={`search ${
                keywords.length > 0 || location.pathname === "/search"
                    ? "search__full"
                    : ""
            }`}
        >
            <input
                id="search"
                type="text"
                onChange={(e) => {
                    setSearch(e.target.value);
                    setKeywords(e.target.value);
                    setNavigate(e.target.value);
                }}
                onKeyDown={handleKeyDown}
                placeholder="Поиск..."
            />
        </div>
    );
}

const mapStateToProps = (store) => {
    return {
        city: store.city.city,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        search: (key) => dispatch(searchByKeywords(key)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
