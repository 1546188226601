import { combineReducers } from "redux";
import { placesReducer } from "./places";
import { userReducer } from "./user";
import { searchReducer } from "./search";
import { cityReducer } from "./city";
import { customerReducer } from "./customer";

const reducer = combineReducers({
  places: placesReducer,
  user: userReducer,
  search: searchReducer,
  city: cityReducer,
  customer: customerReducer
});

export default reducer;
