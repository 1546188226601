import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import ApiService from "services/api-service";
import FormDiscount from "./formDiscount";
import BackButton from "components/BackButton/BackButton";
import Header from "components/Header/Header";
import styles from "./subPages.module.scss";
import { compileTimeslots } from "utils";
import { LinearProgress } from "@mui/material";

function AddDiscount(props) {
    const params = useParams();
    const { id } = params;
    const service = new ApiService();
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [city, setCity] = useState("");
    const [sale, setSale] = useState({
        title: "",
        start: "",
        end: "",
        size: 0,
        count: 0,
        description: "",
        isFullWeek: true,
        isPermanent: true,
        timeslots: {},
        days: [],
    });
    const [imgData, setImgData] = useState([]);

    useEffect(() => {
        (async () => {
            const res = await service.getCurrentCityId(id);
            setCity(res.data.city._id);
        })();
        //eslint-disable-next-line
    }, []);

    const submit = async () => {
        if (loading) return;

        const formData = new FormData();
        formData.append("id", id);
        formData.append("title", sale.title);
        formData.append("size", sale.size);
        formData.append("count", sale.count);
        formData.append("description", sale.description);
        formData.append("city", city);
        formData.append("isPermanent", sale.isPermanent);
        formData.append("isFullWeek", sale.isFullWeek);
        formData.append(
            "timeslots",
            JSON.stringify(compileTimeslots(sale.timeslots))
        );

        if (!sale.isFullWeek) {
            formData.append("days", JSON.stringify(sale.days));
        }

        if (!sale.isPermanent) {
            formData.append("start", sale.start);
            formData.append("end", sale.end);
        }

        if (imgData.length) {
            for (var i = 0; i < imgData.length; i++) {
                formData.append("file_" + i, imgData[i]);
            }
        }

        try {
            setLoading(true);
            const res = await service.addNewDiscount(formData);
            if (res.status === 200) {
                setLoading(false);
                setMessage("Акция была успешно добавлена");
            }
        } catch {
            setLoading(false);
        }
    };

    const valid = () => {
        if (loading) return false;
        const { title, size, count, description, start, end, isPermanent } =
            sale;
        const checkData = Boolean(title && size && count && description);
        setError(!checkData);

        if (!isPermanent && (!start || !end)) {
            setError(true);
            return false;
        }

        return checkData;
    };

    return (
        <>
            <Header />
            {loading && <LinearProgress />}
            <div className={styles.container}>
                <h3 className={styles.titleCustomer}>Добавьте акцию</h3>
                {error && (
                    <div className={styles.error}>
                        Заполните все текстовые поля
                    </div>
                )}
                {message !== "" ? (
                    <div className={styles.notification}>{message}</div>
                ) : null}
                <FormDiscount
                    data={sale}
                    setData={setSale}
                    setImg={setImgData}
                />

                {!message && (
                    <button
                        onClick={() => {
                            if (valid()) {
                                submit();
                            }
                        }}
                        className={styles.btn}
                    >
                        Добавить акцию
                    </button>
                )}
                <BackButton />
            </div>
        </>
    );
}

const mapStateToProps = (store) => ({
    cities: store.city.available,
});

export default connect(mapStateToProps)(AddDiscount);
