import React, { useState } from "react";

import categories from "./CategoryArray";
import { Close, MoreHoriz } from "@mui/icons-material";
import "./CategoryList.scss";

function CategoryList(props) {
    const { list, update } = props;

    const [active, setActive] = useState(false);

    const filteredData = list.map((name) => {
        let item = categories.find(
            (cat) => cat.name.toLowerCase() === name.toLowerCase()
        );

        return (
            <li key={item.name} className="active">
                <button>
                    {item.icon}
                    <span className="title">{item.name}</span>
                </button>
            </li>
        );
    });

    const lightSwitch = () => {
        setActive(!active);
    };

    const defaultData = categories.map((item, i) => {
        return i === 0 || i === 1 || i === 2 ? (
            <li key={item.name}>
                <button onClick={lightSwitch}>
                    {item.icon}
                    <p className="title">{item.name}</p>
                </button>
            </li>
        ) : null;
    });

    const isActive = (name) => {
        return list.indexOf(name);
    };

    const data = categories.map((item) => {
        return (
            <li
                key={item.name}
                className={isActive(item.name) !== -1 ? "active" : null}
            >
                <button
                    onClick={() => {
                        update(item.name);
                    }}
                >
                    {item.icon}
                    <p className="title">{item.name}</p>
                </button>
            </li>
        );
    });

    return (
        <div className={`categoryList ${!active ? "short" : ""}`}>
            <ul>
                {active ? data : list.length === 0 ? defaultData : filteredData}
                <li>
                    <button onClick={lightSwitch}>
                        {!active ? (
                            <MoreHoriz className="category__icon" />
                        ) : (
                            <Close className="category__icon" />
                        )}
                        <p className="title toggle">
                            {active
                                ? "Закрыть"
                                : list.length === 0
                                ? "Еще"
                                : "Изменить"}
                        </p>
                    </button>
                </li>
                {/* <li className="empty"></li> */}
            </ul>
        </div>
    );
}

export default CategoryList;
