import React, { useState } from "react";
import ApiService from "services/api-service";
import FormDiscount from "./formDiscount";
import styles from "./subPages.module.scss";
import { useLocation } from "react-router-dom";
import BackButton from "components/BackButton/BackButton";
import CustomerHeader from "components/CustomerHeader/CustomerHeader";
import { compileTimeslots } from "utils";

function EditDiscount(props) {
    const location = useLocation();
    const { data } = location.state;
    const service = new ApiService();
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sale, setSale] = useState(data);
    const [imgData, setImgData] = useState(null);

    const submit = async () => {
        const formData = new FormData();
        formData.append("title", sale.title);
        formData.append("size", sale.size);
        formData.append("count", sale.count);
        formData.append("description", sale.description);
        formData.append("isPermanent", sale.isPermanent);
        formData.append("isFullWeek", sale.isFullWeek);
        formData.append(
            "timeslots",
            JSON.stringify(compileTimeslots(sale.timeslots))
        );

        if (!sale.isFullWeek) {
            formData.append("days", JSON.stringify(sale.days));
        }

        if (!sale.isPermanent) {
            formData.append("start", sale.start);
            formData.append("end", sale.end);
        }

        if (imgData.length) {
            for (var i = 0; i < imgData.length; i++) {
                formData.append("file_" + i, imgData[i]);
            }
        }

        setLoading(true);
        const res = await service.addNewDiscount(formData);
        setLoading(false);
        if (res.status === 200) {
            setMessage("Акция была успешно добавлена");
        }
    };

    const valid = () => {
        if (loading) return false;
        const { title, size, count, description, start, end, isPermanent } =
            sale;
        const checkData = Boolean(title && size && count && description);
        setError(!checkData);

        if (!isPermanent && (!start || !end)) {
            setError(true);
            return false;
        }

        return checkData;
    };

    return (
        <>
            <CustomerHeader />
            <div className={styles.container}>
                <h3 className={styles.titleCustomer}>Редактировать акцию</h3>
                {error && (
                    <div className={styles.error}>
                        Заполните все текстовые поля
                    </div>
                )}
                {message !== "" ? (
                    <div className={styles.notification}>{message}</div>
                ) : null}
                <FormDiscount
                    submit={submit}
                    data={sale}
                    setData={setSale}
                    setImg={setImgData}
                    message={message}
                    imgData={imgData}
                />
                {message === "" ? (
                    <button
                        onClick={() => {
                            if (valid()) {
                                submit();
                            }
                        }}
                        className={styles.btn}
                    >
                        Редактировать
                    </button>
                ) : null}
                <BackButton />
            </div>
        </>
    );
}

export default EditDiscount;
