import React from "react";
import styles from "./WishesList.module.scss";

function WishesList(props) {
  const { places = [], toPlace } = props;

  const onPlaceDiscount = (size) => {
    let saleName = "";
    if (size >= 0 && size <= 10) {
      saleName = `${styles.low}`;
    } else if (size > 10 && size <= 20) {
      saleName = `${styles.start}`;
    } else if (size > 20 && size < 40) {
      saleName = `${styles.middle}`;
    } else if (size >= 40) {
      saleName = `${styles.high}`;
    }
    return saleName;
  };

  const createPlace = (place) => {
    return (
      <div
        className={styles.place}
        style={{ backgroundImage: "url(" + place.img[0] + ")" }}
        key={place._id}
        onClick={() => {
          toPlace(place._id);
        }}
      >
        <div className={styles.description}>
          <h4>{place.title}</h4>
          <p>{place.address}</p>
        </div>
        <div className={styles.sale + ` ` + onPlaceDiscount(place.sale)}>{place.sale + " %"} </div>
      </div>
    );
  };

  const data = places.map((place) => createPlace(place));

  return places.length !== 0 && data.length !== 0 ? (
    <div className={styles.placeList}>{data}</div>
  ) : (
    <div className={styles.notFound}>
      <img src={require("assets/images/cat.png")} alt="" />
      <p>Вы пока не добавили любимых заведений</p>
    </div>
  );
}

export default WishesList;
