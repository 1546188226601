import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { styled, TextField, Select, MenuItem, InputBase } from "@mui/material";
import { Map, Placemark, withYMaps } from "react-yandex-maps";
import ApiService from "services/api-service";
import { Link, useParams } from "react-router-dom";
import styles from "../sub-pages/subPages.module.scss";
import CustomerHeader from "components/CustomerHeader/CustomerHeader";
import { setCustomerPlaces } from "store/actions/customer";
import Header from "components/Header/Header";
import CategoryList from "components/CategoryList/CategoryList";

const CustomInput = styled(TextField)({
    "& label.Mui-focused": {
        color: "green",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#00d07a",
    },
    "& .MuiOutlinedInput-root": {
        paddingLeft: 10,
        marginBottom: 10,

        "& fieldset": {
            borderColor: "#00d07a",
        },
        "&:hover fieldset": {
            borderColor: "#00d07a",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#00d07a",
        },
    },
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #00d07a",
        fontSize: 16,
        padding: "10px 26px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

function EditDesc(props) {
    const service = new ApiService();
    const params = useParams();
    const { current, user, setPlacesForUser } = props;
    const { id } = params;
    const [data, setData] = useState({
        id: "",
        title: "",
        description: "",
        phone: "",
        address: "",
        city: "",
        start: "07:30",
        end: "18:00",
        mapPoint: [],
    });
    const [message, setMessage] = useState("");
    const [categories, setCategories] = useState([]);
    const [centerMap, setCenterMap] = useState([]);
    const [placeMarkCords, setPlaceMarkCords] = useState([]);

    useEffect(() => {
        if (id !== "new" && current._id) {
            const {
                title,
                description,
                categories,
                phone,
                address,
                city,
                workHours,
            } = current;
            setData({
                id,
                title,
                description,
                phone,
                address,
                city,
                start: workHours.start,
                end: workHours.end,
            });
            setCategories(categories);
        }
    }, [current, id]);

    const updateCategories = (name) => {
        categories.includes(name)
            ? setCategories(categories.filter((el) => el !== name))
            : setCategories([...categories, name]);
    };

    const getCities = () => {
        return user.subscription.cities.map((city) => (
            <MenuItem value={city.name} key={city._id}>
                {city.name}
            </MenuItem>
        ));
    };

    const setMap = (current) => {
        const city = user.subscription.cities.find(
            (city) => city.name === current
        );

        setCenterMap(city.coordinates);
    };

    const setCoordinates = (e) => {
        let target = e.get("target");
        let coordinates = target.geometry.getCoordinates();
        setData((prev) => {
            return { ...prev, mapPoint: coordinates };
        });
    };

    const getFirstTwoNumbers = (arr) => {
        if (arr && arr.length) {
            return {
                start: +String(arr[0]).substring(0, 2),
                end: +String(arr[1]).substring(0, 2),
            };
        }
    };

    const getDifferenceOfCoordinates = (cords1, cords2) => {
        if (!cords1 && !cords2) return null;
        const first = getFirstTwoNumbers(cords1);
        const second = getFirstTwoNumbers(cords2);

        if (!first || !second) return false;

        const firstCheckDiference = first.start - second.start;
        const secondCheckDiference = first.end - second.end;

        if (
            firstCheckDiference <= 1 &&
            firstCheckDiference >= -1 &&
            secondCheckDiference <= 1 &&
            secondCheckDiference >= -1
        ) {
            return true;
        }

        return false;
    };

    const PlacemarkComponent = React.useMemo(() => {
        return ({ ymaps, data }) => {
            if (
                !data.city ||
                !data.address ||
                data.address.length < 7 ||
                placeMarkCords.length
            )
                return null;
            ymaps
                .geocode(`${data.city}, ${data.address}`, {
                    results: 1,
                })
                .then((res) => {
                    let obj = res.geoObjects.get(0);
                    let coordinates = obj.geometry.getCoordinates();
                    const placemark = getDifferenceOfCoordinates(
                        centerMap,
                        coordinates
                    );
                    setPlaceMarkCords(placemark ? coordinates : centerMap);
                    setData((prev) => {
                        return { ...prev, mapPoint: coordinates };
                    });
                })
                .catch((err) => {
                    setData((prev) => {
                        setPlaceMarkCords(centerMap);
                        return { ...prev, mapPoint: centerMap };
                    });
                    console.warn(err);
                });
            return null;
        };
        // eslint-disable-next-line
    }, [placeMarkCords, centerMap]);

    const PlacemarkWithYMaps = React.useMemo(() => {
        return withYMaps(PlacemarkComponent, true, ["geocode"]);
    }, [PlacemarkComponent]);

    const submit = async (e) => {
        e.preventDefault();
        const res = data.id
            ? await service.updateDescription({
                  ...data,
                  categories,
                  status: current.status,
                  workHours: {
                      start: data.start,
                      end: data.end,
                  },
              })
            : await service.createPlace({
                  ...data,
                  categories,
                  workHours: {
                      start: data.start,
                      end: data.end,
                  },
                  user: user.id,
              });
        if (res.status === 200) {
            setMessage("Информация была успешно обновлена");
            setPlacesForUser(res.data);
        }
    };

    return (
        <>
            <Header />
            <CustomerHeader />

            {!data.id && (
                <PlacemarkWithYMaps
                    data={{ city: data.city, address: data.address }}
                />
            )}

            {user.id && (
                <div className={styles.container}>
                    <h3 className={styles.titleCustomer}>
                        {id === "new"
                            ? "Добавить новое заведение"
                            : "Редактировать описание"}
                    </h3>
                    <div className={styles.editDesc}>
                        <CustomInput
                            label="Название заведения"
                            id="custom-css-outlined-input"
                            value={data.title}
                            InputLabelProps={{
                                style: {
                                    fontSize: 17,
                                },
                            }}
                            onChange={(e) => {
                                setData((prev) => {
                                    return { ...prev, title: e.target.value };
                                });
                            }}
                        />

                        <CustomInput
                            label="Описание"
                            InputLabelProps={{
                                style: {
                                    fontSize: 17,
                                },
                            }}
                            onChange={(e) => {
                                setData((prev) => {
                                    return {
                                        ...prev,
                                        description: e.target.value,
                                    };
                                });
                            }}
                            value={data.description}
                            rows={3}
                            multiline
                        />

                        <CustomInput
                            label="Адрес заведения"
                            id="custom-css-outlined-input"
                            value={data.address}
                            InputLabelProps={{
                                style: {
                                    fontSize: 17,
                                },
                            }}
                            onChange={(e) => {
                                setData((prev) => {
                                    return { ...prev, address: e.target.value };
                                });
                            }}
                        />

                        <CustomInput
                            label="Телефон"
                            InputLabelProps={{
                                style: {
                                    fontSize: 17,
                                },
                            }}
                            onChange={(e) => {
                                setData((prev) => {
                                    return { ...prev, phone: e.target.value };
                                });
                            }}
                            value={data.phone}
                        />
                        <div className={styles.timePickers}>
                            <CustomInput
                                type="time"
                                label="Время открытия"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {
                                        fontSize: 17,
                                    },
                                }}
                                onChange={(e) => {
                                    setData((prev) => {
                                        return {
                                            ...prev,
                                            start: e.target.value,
                                        };
                                    });
                                }}
                                value={data.start}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                                sx={{ width: 240 }}
                            />

                            <CustomInput
                                type="time"
                                label="Время закрытия"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {
                                        fontSize: 17,
                                    },
                                }}
                                onChange={(e) => {
                                    setData((prev) => {
                                        return { ...prev, end: e.target.value };
                                    });
                                }}
                                value={data.end}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                                sx={{ width: 240 }}
                            />
                        </div>

                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={data.city}
                            label="City"
                            onChange={(e) => {
                                setData((prev) => {
                                    return { ...prev, city: e.target.value };
                                });
                                setMap(e.target.value);
                            }}
                            input={<BootstrapInput />}
                        >
                            {getCities()}
                        </Select>

                        {centerMap.length !== 0 && !data.id && (
                            <>
                                <p>Выберите на карте ваше заведение:</p>
                                <Map
                                    state={{
                                        center: centerMap,
                                        zoom: 12,
                                        controls: [],
                                    }}
                                    width={700}
                                    height={400}
                                >
                                    <Placemark
                                        modules={[
                                            "geoObject.addon.balloon",
                                            "layout.ImageWithContent",
                                        ]}
                                        geometry={placeMarkCords}
                                        iconContent="120"
                                        options={{
                                            iconLayout: "default#image",
                                            iconImageSize: [46, 60],
                                            iconImageOffset: [-24, -24],
                                            draggable: true,
                                        }}
                                        onDragEnd={setCoordinates}
                                    />
                                </Map>
                            </>
                        )}

                        <p>Выберите категорию вашего заведения:</p>
                        <CategoryList
                            list={categories}
                            update={updateCategories}
                        />

                        {message && (
                            <div className={styles.notification}>{message}</div>
                        )}
                    </div>

                    {!message && (
                        <button className={styles.btn} onClick={submit}>
                            {id === "new" ? "Создать" : "Обновить"}
                        </button>
                    )}

                    <Link to={"/customer/dashboard/"} className={styles.btn}>
                        Вернуться назад
                    </Link>
                </div>
            )}
        </>
    );
}

const mapStateToProps = (store) => {
    return {
        current: store.customer.current,
        user: store.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPlacesForUser: (data) => dispatch(setCustomerPlaces(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDesc);
