import React from "react";
import { Link } from "react-router-dom";
import CustomerHeader from "components/CustomerHeader/CustomerHeader";
import CustomerPlace from "./CustomerPlace/CustomerPlace";
import RequirePermissions from "hoc/RequirePermissions";
import styles from "./CustomerCabinet.module.scss";
import Header from "components/Header/Header";

function CustomerCabinet(props) {
    return (
        <>
            <Header />
            <CustomerHeader />
            <div className={styles.buttons}>
                <RequirePermissions all="manager">
                    <Link to={"/customer/moderation/find/users"}>
                        Добавить подписку
                    </Link>
                </RequirePermissions>
                <RequirePermissions all="manager">
                    <Link to={"/customer/moderation/find/places"}>
                        Модерировать заведения
                    </Link>
                </RequirePermissions>
                <Link to={`/customer/dashboard/edit/description/new`}>
                    Добавить заведение
                </Link>
            </div>
            <CustomerPlace />
        </>
    );
}

export default CustomerCabinet;
