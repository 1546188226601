import { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "./style.scss";

function CropImage(props) {
    const { src, onSave } = props;
    let fileUrl = src;
    const [crop, setCrop] = useState({
        unit: "px",
        x: 25,
        y: 25,
        width: 320,
        height: 160,
    });

    const imageRef = useRef();

    const [croppedImageUrl, setCroppedImageUrl] = useState();

    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {
        setCrop(crop);
    };

    const makeClientCrop = async (crop) => {
        if (crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                imageRef.current,
                crop,
                "newFile.jpeg"
            );
            setCroppedImageUrl(croppedImageUrl);
        }
    };

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(fileUrl);
                fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl);
            }, "image/jpeg");
        });
    };

    const save = async (url) => {
        let blobFile = await fetch(url).then((r) => r.blob());
        onSave(blobFile);
    };

    return (
        <div class="cropper">
            <ReactCrop
                crop={crop}
                onChange={(c) => onCropChange(c)}
                onComplete={(s) => onCropComplete(s)}
                keepSelection
            >
                <img ref={imageRef} src={fileUrl} alt="image_crop" />
            </ReactCrop>

            <div class="cropper__preview">
                <h2>Основная фотография в ленте будет выглядеть так:</h2>
                {croppedImageUrl && <img alt="Crop" src={croppedImageUrl} />}
                <button
                    onClick={() => {
                        save(croppedImageUrl);
                    }}
                >
                    Сохранить
                </button>
            </div>
        </div>
    );
}

export default CropImage;
