import axios from "axios";

export const SEND_USER_DATA = "SEND_USER_DATA";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_ERROR = "SET_USER_ERROR";
export const SEND_USER_LOGOUT = "SET_USER_LOGOUT";
export const SET_USER_COUPON = "SET_USER_COUPON";
export const SET_FAVORITES_DATA = " SET_FAVORITES_DATA";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const SET_PROMOCODES = "SET_PROMOCODES";
export const SET_PERMISSIONS = "SET_PERMISSIONS";

export function checkAuth(data) {
    const { email, password } = data;
    return (dispatch) => {
        dispatch({
            type: SEND_USER_DATA,
            payload: data,
        });
        axios
            .post("/auth/login", {
                email,
                password,
            })
            .then((res) => {
                dispatch({
                    type: SET_USER_DATA,
                    payload: res.data,
                });
                localStorage.setItem("user", JSON.stringify(res.data));
            })
            .catch((err) => {
                if (err.response) {
                    dispatch({
                        type: SET_USER_ERROR,
                        payload: err.response.data,
                    });
                }
            });
    };
}

export function logout(data) {
    return (dispatch) => {
        axios.get("/auth/logout").then((res) => {
            dispatch({
                type: SEND_USER_LOGOUT,
                payload: data,
            });
        });
        localStorage.removeItem("user");
    };
}

export function checkAuthNow() {
    return (dispatch) => {
        axios
            .get("/auth/login")
            .then((res) => {
                if (res.data.isLogin) {
                    dispatch({
                        type: SET_USER_DATA,
                        payload: res.data.userData,
                    });
                }
            })
            .catch((err) => {
                if (err?.response?.status === 401) dispatch(logout());
            });
    };
}

export function deleteNotification(id) {
    return (dispatch) => {
        axios
            .delete("/users/notifications", {
                params: { id },
            })
            .then((res) => {
                dispatch({
                    type: DELETE_NOTIFICATION,
                    payload: id,
                });
            });
    };
}

export function userFromLocalStorage() {
    return (dispatch) => {
        const user = localStorage.getItem("user");
        if (user) {
            dispatch({
                type: SET_USER_DATA,
                payload: JSON.parse(user),
            });
        }
    };
}

export function setFavoritePlace(place) {
    return async (dispatch) => {
        await axios.put(`/users/favorite/${place}`).then((res) =>
            dispatch({
                type: SET_FAVORITES_DATA,
                payload: res.data.favorites,
            })
        );
    };
}

export function getFavoritePlaces() {
    return async (dispatch) => {
        await axios.get("/users/favorite").then((res) =>
            dispatch({
                type: SET_FAVORITES_DATA,
                payload: res.data.favorites,
            })
        );
    };
}

export function getPromocodesByUser() {
    return async (dispatch) => {
        await axios.get("/users/promocodes").then((res) =>
            dispatch({
                type: SET_PROMOCODES,
                payload: res.data.promocodes,
            })
        );
    };
}

export function getPermissionsById(id) {
    return async (dispatch) => {
        await axios.get(`/users/permissions/${id}`).then((res) =>
            dispatch({
                type: SET_PERMISSIONS,
                payload: res.data,
            })
        );
    };
}
