import { Avatar } from "@mui/material";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getPromocodesByUser } from "store/actions/user";
import "./Promocodes.scss";
function Promocodes(props) {
    const { getPromocodes, promocodes, id: user } = props;
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) navigate("/");

        const fetch = async () => {
            await getPromocodes();
        };

        fetch();
        //eslint-disable-next-line
    }, []);

    const onPlaceDiscount = (size) => {
        let saleName = "";

        if (size === 0) {
            saleName = "low";
        } else if (size >= 1 && size <= 20) {
            saleName = "start";
        } else if (size > 20 && size < 40) {
            saleName = "middle";
        } else if (size >= 40) {
            saleName = "high";
        }
        return saleName;
    };

    const getPromocodeBlock = (promocode) => (
        <div className="promocodes__item">
            <div>
                <Avatar
                    src={`/${
                        promocode.img[0]
                            ? promocode.img[0]
                            : promocode.place.img[0]
                    }`}
                    sx={{
                        width: 100,
                        height: 100,
                        marginTop: 1,
                        backgroundColor: "#f49df4",
                        border: "3px solid #f49df4",
                        fontSize: 15,
                        marginRight: 2,
                    }}
                >
                    {promocode.place.title[0]}
                </Avatar>
                <div
                    className={`promocodes__item-size ${onPlaceDiscount(
                        promocode.size
                    )}`}
                >
                    {`${promocode.size}%`}
                </div>
            </div>
            <div className="promocodes__item-description">
                <h2>{promocode.title}</h2>
                <Link
                    to={{
                        pathname: `/place/${promocode.place._id}`,
                    }}
                    className="promocodes__item-place"
                >
                    <Avatar
                        src={`/${promocode.place.img[0]}`}
                        sx={{
                            width: 20,
                            height: 20,
                            backgroundColor: "#f49df4",
                            fontSize: 12,
                            marginRight: 1,
                        }}
                    >
                        {promocode.place.title[0]}
                    </Avatar>

                    <h3>{promocode.place.title}</h3>
                </Link>

                <p>{promocode.description}</p>
                <div className="promocodes__item-coupon">
                    {promocode.coupon}
                </div>
            </div>
        </div>
    );

    return (
        <div className="promocodes">
            <h2 className="promocodes__title">Ваши промокоды</h2>

            <div className="promocodes__list">
                {promocodes.map((promocode) => getPromocodeBlock(promocode))}
            </div>
        </div>
    );
}

const mapStateToProps = (store) => {
    return {
        promocodes: store.user.promocodes,
        id: store.user.id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPromocodes: () => dispatch(getPromocodesByUser()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Promocodes);
