import React from "react";
// import { connect } from "react-redux";

import UserWidget from "components/UserWidget/UserWidget";
// import NotificationChart from "components/NotificationChart/NotificationChart";

import styles from "./Header.module.scss";
import { useNavigate } from "react-router-dom";

function Header(props) {
    const navigate = useNavigate();

    return (
        <div className={styles.header}>
            <div className={styles.header__container}>
                <h1
                    className={styles.header__logo}
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    <span>sweet</span>.discount
                </h1>

                <nav className={styles.header__nav}>
                    <UserWidget />
                </nav>
                {/* <NotificationChart toPlace={routeToPlace} /> */}
            </div>
        </div>
    );
}


export default Header;
