import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Alert } from "@mui/material";
import Img from "../../../components/formHelpers/Img";
import styles from "./CustomerPlace.module.scss";

function CustomerPlace(props) {
    const { place } = props;

    const { status, img, _id: id } = place;

    const isActive = () => {
        return status === "active";
    };

    const renderPlace = () => (
        <div className={styles.container}>
            {!isActive() && (
                <Alert variant="outlined" severity="warning">
                    {status === "pending"
                        ? "Заведение находится на проверке модератором и скоро будет доступно для работы"
                        : "Вам нужно обновить данные вашего заведение, возможно какие то данные нужно описать более подробно"}
                </Alert>
            )}
            <div className={styles.place}>
                <div className={styles.avatar}>
                    <Img src={`/${img[0]}`} alt="" />
                </div>
                <div className={styles.buttons}>
                    <Link
                        className={!isActive() ? styles.disabled : null}
                        to={isActive() ? `/customer/dashboard/add/${id}` : "#"}
                    >
                        Добавить акцию
                    </Link>
                    <Link to={`/customer/dashboard/edit/images/${id}`}>
                        {img.length
                            ? "Изменить изображения"
                            : "Добавить изображения"}
                    </Link>
                    <Link
                        className={
                            status === "edit" || isActive()
                                ? null
                                : styles.disabled
                        }
                        to={
                            status === "edit" || status === "active"
                                ? `/customer/dashboard/edit/description/${id}`
                                : "#"
                        }
                    >
                        Редактировать описание
                    </Link>
                    <Link
                        className={!isActive() ? styles.disabled : null}
                        to={
                            isActive()
                                ? `/customer/dashboard/show/current/${id}`
                                : "#"
                        }
                    >
                        Текущие акции
                    </Link>
                    <Link
                        className={!isActive() ? styles.disabled : null}
                        to={
                            isActive()
                                ? `/customer/dashboard/show/last/${id}`
                                : "#"
                        }
                    >
                        Прошедшие акции
                    </Link>
                </div>
            </div>
        </div>
    );

    return id ? renderPlace() : null;
}

const mapStateToProps = (store) => {
    return {
        place: store.customer.current,
    };
};

export default connect(mapStateToProps)(CustomerPlace);
