import { Link } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import "./Place.scss";
import Img from "components/formHelpers/Img";

function Place(props) {
    const { id, title, address, sale, views, gotCoupon, img } = props;

    const onPlaceDiscount = (size) => {
        let saleName = "";

        if (size === 0) {
            saleName = "low";
        } else if (size >= 1 && size <= 20) {
            saleName = "start";
        } else if (size > 20 && size < 40) {
            saleName = "middle";
        } else if (size >= 40) {
            saleName = "high";
        }
        return saleName;
    };

    return (
        <Link
            to={{
                pathname: `/place/${id}`,
            }}
            key={id}
            className="place"
        >
            <Img src={`/${img}`} alt={title} isSmall />

            <div className="description">
                <h2>{title}</h2>
                <p>{address}</p>
            </div>
            {typeof sale === "number" && (
                <div className={`sale ${onPlaceDiscount(sale)}`}>
                    {sale + " %"}{" "}
                </div>
            )}
            <div className="additional">
                {Boolean(views) && (
                    <p className="views">
                        {views}
                        <Visibility />
                    </p>
                )}
                {gotCoupon && <p className="coupons">{gotCoupon}</p>}
            </div>
        </Link>
    );
}

export default Place;
