import { SET_SEARCH_REQUEST, SET_SEARCH_SUCCESS } from "../actions/search";

const initialState = {
  results: [],
  isLoading: false,
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SET_SEARCH_SUCCESS:
      return { ...state, results: action.payload, isLoading: false };
    default:
      return state;
  }
};
